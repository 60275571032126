import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_main = _resolveComponent("el-main");
  return _openBlock(), _createBlock(_component_el_main, {
    class: "application"
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view)]),
    _: 1
  });
}