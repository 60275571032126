import { computed, defineComponent } from "vue";
export default defineComponent({
  name: "VariantCheckbox",
  emits: ["mouse:over", "mouse:leave"],
  props: {
    label: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    iconClass: {
      type: String,
      required: false
    },
    isActive: {
      type: Boolean,
      required: true
    },
    isPrimary: {
      type: Boolean,
      required: true
    },
    emphasize: {
      type: Boolean,
      required: false
    }
  },
  setup(props) {
    const titleSafe = computed(() => props.title || props.label);
    const stopPropagation = e => e.stopPropagation();
    return {
      titleSafe,
      stopPropagation
    };
  }
});