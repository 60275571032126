import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-36a7b93c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "product-details-table",
  itemscope: "",
  itemtype: "http://schema.org/Dataset"
};
const _hoisted_2 = {
  class: "countryLinks"
};
const _hoisted_3 = {
  key: 0,
  class: "warnings"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_details_row = _resolveComponent("details-row");
  const _component_router_link = _resolveComponent("router-link");
  const _component_add_to_cart = _resolveComponent("add-to-cart");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("table", _hoisted_1, [_createElementVNode("tbody", null, [_createVNode(_component_details_row, {
    label: "Sheet Title",
    content: _ctx.$props.product?.title,
    itemprop: "name"
  }, null, 8, ["content"]), _createVNode(_component_details_row, {
    label: "Sheet Index",
    content: _ctx.$props.product?.mapIndex
  }, null, 8, ["content"]), _createVNode(_component_details_row, {
    label: "Year",
    content: _ctx.$props.product?.publishedDate
  }, null, 8, ["content"]), _createVNode(_component_details_row, {
    label: 'Language' + ((_ctx.product?.languages?.length || 0) > 1 ? 's' : ''),
    content: _ctx.product?.sku ? _ctx.product?.languages?.join(', ') : undefined
  }, null, 8, ["label", "content"]), _createVNode(_component_details_row, {
    label: "Series Name",
    content: _ctx.publication.name,
    itemprop: _ctx.productId ? undefined : 'name'
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "publicationName", {
      publication: _ctx.publication
    }, () => [_ctx.productId ? (_openBlock(), _createBlock(_component_router_link, {
      key: 0,
      to: {
        name: 'publication',
        params: {
          permId: _ctx.publication.permId,
          slug: _ctx.getPublicationSlug(_ctx.publication)
        }
      },
      class: "publication-link"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.publication.name), 1)]),
      _: 1
    }, 8, ["to"])) : _createCommentVNode("", true)], true)]),
    _: 3
  }, 8, ["content", "itemprop"]), _createVNode(_component_details_row, {
    label: "Publisher",
    content: _ctx.publication.publisherName
  }, {
    default: _withCtx(() => [_ctx.publisher?.gid ? (_openBlock(), _createBlock(_component_router_link, {
      key: 0,
      to: {
        name: 'byPublisher',
        params: {
          permId: _ctx.publisher.permId,
          slug: _ctx.getPublisherSlug(_ctx.publisher)
        }
      }
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.publisher.fullname), 1)]),
      _: 1
    }, 8, ["to"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["content"]), _createVNode(_component_details_row, {
    label: "Type",
    content: _ctx.publication.typeLabel
  }, null, 8, ["content"]), _createVNode(_component_details_row, {
    label: "Sub-Type",
    content: _ctx.publication.subtypeLabel
  }, null, 8, ["content"]), _createVNode(_component_details_row, {
    label: "Scale/Resolution",
    content: _ctx.scaleName
  }, null, 8, ["content"]), _createVNode(_component_details_row, {
    label: "Region",
    content: _ctx.region
  }, null, 8, ["content"]), _createVNode(_component_details_row, {
    label: "Countries",
    content: _ctx.countryNames
  }, {
    default: _withCtx(() => [_createElementVNode("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.covcountries, country => {
      return _openBlock(), _createElementBlock("li", {
        key: country.code
      }, [_createVNode(_component_router_link, {
        to: {
          name: 'byCountry',
          params: {
            countryCode: country.code,
            slug: country.name
          }
        }
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(country.name), 1)]),
        _: 2
      }, 1032, ["to"])]);
    }), 128))])]),
    _: 1
  }, 8, ["content"]), _createVNode(_component_details_row, {
    label: 'Language' + ((_ctx.publication?.languages?.length || 0) > 1 ? 's' : ''),
    content: !_ctx.product?.sku ? _ctx.publication.languages?.join(', ') : undefined
  }, null, 8, ["label", "content"]), !_ctx.productId && _ctx.publication?.itemCount ? (_openBlock(), _createBlock(_component_details_row, {
    key: 0,
    label: "Number of items",
    content: _ctx.publication.itemCount
  }, null, 8, ["content"])) : _createCommentVNode("", true), !_ctx.productId && _ctx.publication?.years ? (_openBlock(), _createBlock(_component_details_row, {
    key: 1,
    label: "Years of publication",
    content: _ctx.publicationYearRange
  }, null, 8, ["content"])) : _createCommentVNode("", true), _createVNode(_component_details_row, {
    label: "description",
    style: {
      "display": "none"
    },
    itemprop: "description",
    content: _ctx.description
  }, null, 8, ["content"])])]), _createVNode(_component_el_row, {
    class: "action-container"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, null, {
      default: _withCtx(() => [_ctx.warnings && _ctx.warnings.length > 0 ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.warnings, (w, idx) => {
        return _openBlock(), _createElementBlock("li", {
          key: idx
        }, _toDisplayString(w), 1);
      }), 128))])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "actions", {
        product: _ctx.$props.product
      }, () => [_ctx.product?.gid !== undefined ? (_openBlock(), _createBlock(_component_add_to_cart, {
        key: 0,
        product: _ctx.product,
        preselection: _ctx.preselection,
        onAddToCart: _ctx.onAddSingleToCart,
        "onSelection:changed": _ctx.onSelectionChanged
      }, null, 8, ["product", "preselection", "onAddToCart", "onSelection:changed"])) : _createCommentVNode("", true)], true)]),
      _: 3
    })]),
    _: 3
  })], 64);
}