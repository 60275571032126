import "core-js/modules/es.array.push.js";
import { getPublicationSlug } from "@/lib/evg-shop-ext";
import store from "@/store";
import { apiModule } from "@/store/api";
import { explorerModule } from "@/store/explorer";
import { searchModule } from "@/store/search";
import { computed, defineComponent, reactive, ref } from "vue";
const scoredSearchResultOfPublicationComparer = (one, other) => {
  let d = other.score - one.score;
  if (d !== 0) {
    return d;
  }
  if (one.result.scaleValue) {
    if (other.result.scaleValue) {
      return one.result.scaleValue - other.result.scaleValue;
    }
    return -1;
  }
  if (other.result.scaleValue) {
    return 1;
  }
  return 0;
};
const allConfigs = {
  series_in_catalog: {
    title: "Series Results",
    isLoading: false,
    onChange: search => apiModule.searchClient.get(search, undefined).then(results => {
      const sortedResults = results.sort(scoredSearchResultOfPublicationComparer);
      return sortedResults.map(r => ({
        label: r.result.name,
        to: {
          name: "publication",
          params: {
            permId: r.result.permId,
            slug: getPublicationSlug(r.result)
          }
        }
      }));
    }),
    data: []
  },
  series_in_map: {
    title: "Series Results",
    isLoading: false,
    onChange: search => apiModule.searchClient.get(search, undefined).then(results => {
      const sortedResults = results.sort(scoredSearchResultOfPublicationComparer);
      return sortedResults.map(r => ({
        label: r.result.name,
        to: {
          name: "map"
        },
        item: r.result
      }));
    }),
    onClick: lnk => {
      try {
        const p = lnk.item;
        explorerModule.activatePublication(p);
      } catch (error) {
        // do nothing
      }
    },
    data: []
  },
  location: {
    title: "Location Results",
    isLoading: false,
    onChange: search => apiModule.searchClient.geocode(search, undefined).then(results => results.map(loc => ({
      label: loc.formatted,
      to: {
        name: "map"
      },
      item: loc
    }))),
    onClick: lnk => {
      try {
        const b = lnk.item.bounds;
        explorerModule.setCurrentViewBounds(b);
        searchModule.updateAOI([[b[0], b[1]], [b[2], b[3]]]);
      } catch (error) {
        // do nothing
      }
    },
    data: []
  }
};
export default defineComponent({
  name: "SearchInput",
  directives: {
    "click-outside": {
      beforeMount(el, binding) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            binding.value(event, el);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unmounted(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      }
    }
  },
  props: {
    // only pre-defined configurations can be selected when using this component
    // the narrow usage of this component fits the needs of our application currently
    // if a more general usage of this component is needed consider refactoring into two components (configuration specific and general use)
    // where the configuration specific component relies on the more general use component
    configNames: {
      type: Array,
      required: false
    }
  },
  setup(props) {
    const search = ref("");
    const isDisabled = ref(false);
    const showResults = ref(false);
    const isMobile = computed(() => store.getters.getIsMobile);
    const config = computed(() => buildConfigs(props.configNames));
    const settings = reactive(config);
    const doSearch = () => {
      showResults.value = true;
      isDisabled.value = true;
      const resp = settings.value.map(config => {
        config.isLoading = true;
        config.data = [];
        return config.onChange(search.value).then(results => {
          config.data.push(...results);
        }).finally(() => {
          config.isLoading = false;
        });
      });
      Promise.all(resp).finally(() => {
        isDisabled.value = false;
      });
    };
    const resetSearchInput = () => {
      showResults.value = false;
      search.value = "";
    };
    const onClick = (cfg, item) => {
      cfg.onClick && cfg.onClick(item);
      showResults.value = false;
    };
    function buildConfigs(configNames) {
      if (configNames && configNames.length > 0) {
        return configNames.map(k => {
          if (isMobile.value && k === "series_in_map") {
            // map isn't mobile friendly, transform to catalog equivalent
            k = "series_in_catalog";
          }
          return k;
        }).filter(k => isMobile.value ? k !== "location" : true) // location isn't mobile friendly
        .map(k => allConfigs[k]);
      }
      if (isMobile.value) {
        return [allConfigs["series_in_catalog"]];
      }
      return [allConfigs["series_in_map"], allConfigs["location"]];
    }
    return {
      search,
      doSearch,
      settings,
      isDisabled,
      resetSearchInput,
      showResults,
      onClick
    };
  }
});