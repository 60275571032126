import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { flagToVariants, noneVariant } from "@/lib/evg-shop-ext";
import isEqual from "lodash/isEqual";
import clone from "lodash/clone";
import { snipcartModule } from "@/store/snipcart";
import VariantCheckboxGroup from "./VariantCheckboxGroup.vue";
export default defineComponent({
  name: "AddToCart",
  components: {
    VariantCheckboxGroup
  },
  emits: ["add-to-cart", "selection:changed"],
  props: {
    product: {
      type: Object,
      required: true
    },
    preselection: {
      type: Object,
      required: false
    },
    size: {
      type: String,
      default: "medium"
    }
  },
  setup(props, {
    emit
  }) {
    const selectedVariants = ref(clone(props.preselection || noneVariant));
    const noneSelected = computed(() => isEqual(selectedVariants.value, noneVariant));
    const somePurchasableSelected = computed(() => selectedVariants.value.data && sales.value.data || selectedVariants.value.image && sales.value.image || selectedVariants.value.print && sales.value.print);
    const sales = computed(() => flagToVariants(props.product.sales));
    const exposure = computed(() => flagToVariants(props.product.exposure));
    const priceFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    const price = computed(() => {
      let p = 0;
      if (selectedVariants.value.print && sales.value.print) {
        p += props.product.printPrice || 0;
      }
      if (selectedVariants.value.image && sales.value.image) {
        p += props.product.imagePrice || 0;
      }
      if (selectedVariants.value.data && sales.value.data) {
        p += props.product.dataPrice || 0;
      }
      return p;
    });
    const variantPriceInfo = ref();
    const inCart = computed(() => snipcartModule.inCart(props.product.sku));
    const buttonLabel = computed(() => noneSelected.value ? "Select Options" : somePurchasableSelected.value ? "Add To Cart" : "Ask Us");
    const onAddToCart = () => {
      emit("add-to-cart", props.product, selectedVariants.value);
      selectedVariants.value = clone(noneVariant);
    };
    const hovering = ref();
    const onMouseOver = t => {
      hovering.value = t;
    };
    const onMouseLeave = t => {
      if (hovering.value === t) {
        hovering.value = undefined;
      }
    };
    watch(() => selectedVariants.value, () => {
      variantPriceInfo.value = undefined;
      emit("selection:changed", props.product, selectedVariants.value);
    }, {
      deep: true
    });
    watch(() => hovering.value, t => {
      if (t === undefined) {
        variantPriceInfo.value = undefined;
        return;
      }
      let hp = undefined;
      let info = undefined;
      switch (t) {
        case "Paper":
          if (sales.value.print === true) {
            hp = props.product.printPrice;
            if (selectedVariants.value.print && hp) {
              hp = -hp;
            }
          } else {
            info = !selectedVariants.value.print;
          }
          break;
        case "Image":
          if (sales.value.image === true) {
            hp = props.product.imagePrice;
            if (selectedVariants.value.image && hp) {
              hp = -hp;
            }
          } else {
            info = !selectedVariants.value.image;
          }
          break;
        case "Data":
          if (sales.value.data === true) {
            hp = props.product.dataPrice;
            if (selectedVariants.value.data && hp) {
              hp = -hp;
            }
          } else {
            info = !selectedVariants.value.data;
          }
          break;
        default:
          break;
      }
      if (hp !== undefined) {
        const s = hp < 0 ? " - " : " + ";
        variantPriceInfo.value = s + priceFormatter.format(Math.abs(hp));
      } else if (info !== undefined) {
        variantPriceInfo.value = (info ? " + " : " - ") + `Ask Us (${t})`;
      }
      return;
    });
    watch(() => props.preselection, (current, previous) => {
      if (!isEqual(current, previous)) {
        selectedVariants.value = clone(props.preselection || noneVariant);
      }
    }, {
      deep: true
    });
    onMounted(() => snipcartModule.ensureInitialized());
    return {
      sales,
      exposure,
      price,
      priceFormatter,
      variantPriceInfo,
      inCart,
      noneSelected,
      buttonLabel,
      onAddToCart,
      onMouseOver,
      onMouseLeave,
      selectedVariants
    };
  }
});