import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_evg_source_xyz = _resolveComponent("evg-source-xyz");
  const _component_ol_source_bingmaps = _resolveComponent("ol-source-bingmaps");
  const _component_ol_source_osm = _resolveComponent("ol-source-osm");
  const _component_ol_tile_layer = _resolveComponent("ol-tile-layer");
  return _openBlock(), _createBlock(_component_ol_tile_layer, {
    className: "basemap"
  }, {
    default: _withCtx(() => [_ctx.basemap?.kind === 'xyz' ? (_openBlock(), _createBlock(_component_evg_source_xyz, {
      key: 0,
      url: _ctx.basemap.url,
      "wrap-x": true,
      attributions: _ctx.basemap.attribution,
      "attributions-collapsible": true,
      "image-smoothing": true
    }, null, 8, ["url", "attributions"])) : _ctx.basemap?.kind === 'bing' ? (_openBlock(), _createBlock(_component_ol_source_bingmaps, {
      key: 1,
      "wrap-x": true,
      "api-key": _ctx.basemap.key,
      "imagery-set": _ctx.basemap.imagerySet,
      "attribution-collapsible": true
    }, null, 8, ["api-key", "imagery-set"])) : (_openBlock(), _createBlock(_component_ol_source_osm, {
      key: 2
    }))]),
    _: 1
  });
}