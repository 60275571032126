/* eslint-disable @typescript-eslint/no-explicit-any */
import { IProduct } from "@/lib/evg-shop";
import { checkFlag, VariantLabel, Variants } from "@/lib/evg-shop-ext";
import {
  CartItem,
  CartState,
  ProductDefinition,
  SnipcartSDK,
  SnipCartState,
  RouteChange,
} from "@/lib/snipcart";
import { RequestInfoClient } from "@/lib/evg-shop";
import {
  VuexModule,
  getModule,
  Module,
  Mutation,
  Action,
} from "vuex-module-decorators";
import { ElNotification as Notification } from "element-plus";
import { debounce, isEqual } from "lodash";

export const getSnipcartObject = (
  product: IProduct,
  selectedTypes: Variants,
  snipcart_callback_url: string,
  seriesName?: string
): ProductDefinition[] => {
  const items: ProductDefinition[] = [];
  const getItemBase = (kind: VariantLabel): ProductDefinition => {
    const k = kind.charAt(0);
    return {
      id: `${product.sku}-${k}`,
      description: [
        product.mapIndex ? product.title : undefined,
        product.scaleName,
        product.publishedDate?.toString(),
        kind.toUpperCase(),
      ]
        .filter((s: string | undefined) => !!s)
        .join(" - "),
      name: product.mapIndex ?? product.title ?? product.permId,
      image: product.thumbnail
        ? `${store.state.baseUrl}/thumbnail/${product.thumbnail}`
        : undefined,
      price: 0,
      url: `${snipcart_callback_url}/api/Product/snipcart/${product.sku}-${k}`,
      quantity: 1,
      maxQuantity: 1,
      taxable: true,
      shippable: false,
      metadata: {
        isRfp: true,
        sku: product.sku,
        kind,
        seriesName,
      },
    } as ProductDefinition;
  };
  if (selectedTypes.print) {
    const isPrintSalesFlag = checkFlag(0, product.sales);
    const isStockSalesFlag = checkFlag(3, product.sales);
    const salesAllowed = isPrintSalesFlag || isStockSalesFlag;
    const item = getItemBase("Paper");
    item.metadata.isRfp = !(salesAllowed && product.printPrice !== undefined);
    if (salesAllowed && product.printPrice) {
      item.price = product.printPrice;
      item.maxQuantity = undefined;
    }
    if (item.metadata.isRfp === false) {
      item.customFields = [
        {
          name: "Paper Type",
          type: "dropdown",
          value: "standard",
          options:
            isStockSalesFlag && !isPrintSalesFlag
              ? "standard"
              : "tyvek[+12.95]|polypropylene[+9.95]|standard",
        },
      ];
    }
    item.shippable = true;
    items.push(item);
  }
  if (selectedTypes.image) {
    const salesAllowed = checkFlag(1, product.sales);
    const item = getItemBase("Image");
    item.metadata.isRfp = !(salesAllowed && product.imagePrice !== undefined);
    if (salesAllowed && product.imagePrice) {
      item.price = product.imagePrice;
    }
    if (item.metadata.isRfp === false) {
      item.customFields = [
        {
          name: "Image Format",
          type: "dropdown",
          value: "standard",
          options: "georeferenced & cropped[+28]|georeferenced[+19]|standard",
        },
      ];
    }
    items.push(item);
  }
  if (selectedTypes.data) {
    const salesAllowed = checkFlag(2, product.sales);
    const item = getItemBase("Data");
    item.metadata.isRfp = !(salesAllowed && product.dataPrice !== undefined);
    if (salesAllowed && product.dataPrice) {
      item.price = product.dataPrice;
    }
    items.push(item);
  }
  return items;
};

export interface IProductSelection {
  product: IProduct;
  selection?: Variants;
  seriesName?: string;
}

export interface IAddManyResult {
  success?: string[];
  skipped?: string[];
  failed?: string[];
}

class AddManyResult implements IAddManyResult {
  success: string[] = [];
  skipped: string[] = [];
  failed: string[] = [];

  merge(other: IAddManyResult) {
    if (other.success && other.success.length > 0) {
      this.success?.push(...other.success);
    }
    if (other.skipped && other.skipped.length > 0) {
      this.skipped?.push(...other.skipped);
    }
    if (other.failed && other.failed.length > 0) {
      this.failed?.push(...other.failed);
    }
  }
}

@Module({ namespaced: true, name: "snipcart", dynamic: true, store })
export default class SnipcartModule extends VuexModule {
  backendState_: SnipCartState;
  storeHook?: () => void;
  sdkHooks?: Array<() => void>;
  sdk_: SnipcartSDK;
  preSelections: { [permId: string]: IProductSelection };

  constructor(options: any) {
    super(options);
    this.backendState_ = {} as SnipCartState;
    this.sdk_ = {} as SnipcartSDK;
    this.preSelections = {};
    const reInitialize = () => {
      this.initialize();
    };
    document.addEventListener("snipcart.ready", reInitialize);
    this.initialize();
  }

  get count(): number {
    return this.current?.cart.items.count || 0;
  }

  get isInitialized(): boolean {
    return !isEqual(this.backendState_, {});
  }

  get current(): SnipCartState | undefined {
    if (!this.isInitialized) {
      return this.sdk_?.store?.getState();
    }
    return this.backendState_;
  }

  get items(): CartItem[] {
    return this.current?.cart.items.items || [];
  }

  get customer(): BaseCustomerState | undefined {
    return this.current?.customer;
  }

  get hasPreselections(): boolean {
    return Object.keys(this.preSelections).length > 0;
  }

  get selected(): string[] {
    return Object.keys(this.preSelections);
  }

  get getPreselection(): (permId?: string) => Variants | undefined {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return function (permId?: string) {
      if (!permId) return undefined;
      return self.preSelections[permId]?.selection;
    };
  }

  get inCart(): (sku?: string) => Variants | undefined {
    const currentItems = this.items;
    return function (sku?: string) {
      if (!sku) return undefined;
      return currentItems
        .filter((item: CartItem) => item.metadata?.sku === sku)
        .map(
          (item: CartItem) =>
            ({
              print: item.metadata?.kind === "Paper",
              image: item.metadata?.kind === "Image",
              data: item.metadata?.kind === "Data",
            } as Variants)
        )
        .reduce(
          (p, c) => ({
            print: p.print || c.print,
            image: p.image || c.image,
            data: p.data || c.data,
          }),
          {
            print: false,
            image: false,
            data: false,
          }
        );
    };
  }

  get rfpClient(): RequestInfoClient {
    return apiModule.rfpClient;
  }

  get rfpItems(): CartItem[] {
    return this.items.filter((item: CartItem) => item.metadata?.isRfp === true);
  }

  get purchaseItems(): CartItem[] {
    return this.items.filter((item: CartItem) => item.metadata?.isRfp !== true);
  }

  @Mutation
  initialize(): void {
    const sdk = (window as any).Snipcart as SnipcartSDK | undefined;
    if (this.sdk_ === sdk) {
      return;
    }
    if (!sdk) {
      return;
    }
    if (this.storeHook) {
      this.storeHook();
      this.storeHook = undefined;
    }
    if (this.sdkHooks && this.sdkHooks.length > 0) {
      let h: (() => void) | undefined;
      while ((h = this.sdkHooks.pop()) !== undefined) {
        try {
          h();
        } catch {
          // ignore; old sdk object is no longer valid; neither are its hooks
        }
      }
    }
    this.sdkHooks = [];
    this.sdk_ = sdk;
    this.backendState_ = sdk.store.getState();
    this.storeHook = sdk.store.subscribe(() => {
      snipcartModule.setCurrent(sdk.store.getState());
    });
    this.sdk_.events.on("snipcart.initialized", (state: SnipCartState) => {
      snipcartModule.setCurrent(state);
    });
    this.sdk_.events.on("snipcart.initialization.error", () => {
      snipcartModule.backendState_ = {} as SnipCartState;
    });
    const onCartConfirmed = (cartConfirmResponse: CartState) => {
      const itemCollection = cartConfirmResponse.items;
      const sKUs = itemCollection.items
        .filter((i: CartItem) => i.metadata?.isRfp === true)
        .map((item: CartItem) => item.id);
      if (sKUs && sKUs.length > 0) {
        const { name, country, phone } = cartConfirmResponse.billingAddress;
        const email = cartConfirmResponse.email;
        snipcartModule.rfpClient
          .post(
            name,
            undefined,
            country,
            null,
            null,
            null,
            email,
            phone,
            null,
            "Processed during customer checkout; please lookup associated order.",
            sKUs,
            null,
            null
          )
          .catch(() => {
            Notification({
              title: "Problem sending requests",
              message:
                "Please contact us directly via email: evg_web_support@eastview.com",
              position: "bottom-right",
              type: "warning",
              duration: 10000,
            });
            apiModule.clearCsrfToken();
          });
      }
    };

    this.sdkHooks.push(this.sdk_.events.on("cart.confirmed", onCartConfirmed));

    const checkShouldOpenRfpModal = () => {
      const purchaseItems = snipcartModule.purchaseItems;
      const rfpProducts = snipcartModule.rfpItems;
      if (!purchaseItems.length && rfpProducts.length) {
        // user can only get to checkout if they have purchase items
        // so if they just left checkout and have no purchase items but do have rfp products
        // then we know that they were booted out for deleting purchase items and we should open
        // the rfp modal so that they can finish processing their order that way to avoid
        // the issue where there are empty orders GEOW-774
        store.commit("setIsRfpModalOpen", true);
      }
    };
    const checkShouldOpenModalDebounced = debounce(
      checkShouldOpenRfpModal,
      600
    );

    const onRouteChanged = (routesChange: RouteChange) => {
      const didLeaveCheckout =
        routesChange.from !== "/" && routesChange.to === "/";
      if (didLeaveCheckout) {
        checkShouldOpenModalDebounced();
      }
    };

    this.sdkHooks.push(
      this.sdk_.events.on("theme.routechanged", onRouteChanged)
    );
  }

  @Mutation
  setCurrent(state: SnipCartState): void {
    if (
      !isEqual(state.cart?.items, this.backendState_.cart?.items) ||
      !isEqual(state.customer, this.backendState_.customer)
    ) {
      this.backendState_ = state;
    }
  }

  @Mutation
  select(payload: IProductSelection): void {
    const permId = payload.product.permId;
    const selection = payload.selection;
    if (permId === undefined) return;
    if (!(selection?.print || selection?.image || selection?.data)) {
      if (permId in this.preSelections) {
        delete this.preSelections[permId];
      }
    } else {
      this.preSelections[permId] = payload;
    }
  }

  @Mutation
  ensureInitialized(): void {
    try {
      this.backendState_ = this.sdk_.store.getState();
    } catch {
      snipcartModule.initialize();
    }
  }

  @Action
  async addSingleToCart(payload: IProductSelection): Promise<IAddManyResult> {
    this.ensureInitialized();
    if (!payload.selection) return Promise.reject();
    let items = getSnipcartObject(
      payload.product,
      payload.selection,
      store.state.snipcartUrl,
      payload.seriesName
    );
    const newIds = items.map((i: ProductDefinition) => i.id);
    const alreadyInCart = this.items
      .filter(
        (i: CartItem) => newIds.includes(i.id) && i.metadata.kind !== "Paper"
      )
      .map((i: CartItem) => i.id);
    const result = {
      success: [],
      skipped: alreadyInCart,
      failed: [],
    } as IAddManyResult;
    items = items.filter(
      (i: ProductDefinition) => !alreadyInCart.includes(i.id)
    );
    if (items.length > 0) {
      await this.sdk_.api.cart.items.add(...items);
      result.success = items.map((i: ProductDefinition) => i.id);
    }
    return result;
  }

  @Action
  async addManyToCart(subsetSkus?: string[]): Promise<IAddManyResult> {
    this.ensureInitialized();
    let permIds = Object.keys(this.preSelections);
    if (subsetSkus && subsetSkus.length > 0) {
      permIds = permIds.filter((s: string) => subsetSkus.includes(s));
    }
    const result = new AddManyResult();
    for (let index = 0; index < permIds.length; index++) {
      const permId = permIds[index];
      try {
        const selection = this.preSelections[permId];
        const addResults = await this.addSingleToCart(selection);
        delete this.preSelections[permId];
        result.merge(addResults);
      } catch (error) {
        result.failed?.push(permId);
      }
    }
    if ((result.failed?.length ?? 0) === 0) {
      return result;
    }
    throw result;
  }

  @Action
  async resetItems(): Promise<void> {
    this.ensureInitialized();
    await this.sdk_.api.cart.items.replace();
  }

  @Action
  async removeItem(item: CartItem): Promise<void> {
    this.ensureInitialized();
    if (!item.uniqueId) return;
    await this.sdk_.api.cart.items.remove(item.uniqueId);
  }

  @Action
  async updateItem(item: CartItem): Promise<void> {
    this.ensureInitialized();
    await this.sdk_.api.cart.items.update(item);
  }
}

import store from ".";
import { apiModule } from "./api";
import { BaseCustomerState } from "@/lib/snipcart/customer";
export const snipcartModule = getModule(SnipcartModule, store);
