import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_header = _resolveComponent("el-header");
  const _component_el_footer = _resolveComponent("el-footer");
  const _component_rfp_modal = _resolveComponent("rfp-modal");
  const _component_el_container = _resolveComponent("el-container");
  return _openBlock(), _createBlock(_component_el_container, {
    class: _normalizeClass(["app-container", _ctx.layout])
  }, {
    default: _withCtx(() => [_createVNode(_component_el_header, null, {
      default: _withCtx(() => [_createVNode(_component_router_view, {
        name: "header"
      })]),
      _: 1
    }), _createVNode(_component_router_view), _createVNode(_component_el_footer, null, {
      default: _withCtx(() => [_createVNode(_component_router_view, {
        name: "footer"
      })]),
      _: 1
    }), _createVNode(_component_rfp_modal)]),
    _: 1
  }, 8, ["class"]);
}