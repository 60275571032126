import "core-js/modules/es.array.push.js";
import { computed, defineComponent } from "vue";
import router from "@/router";
import InlineSvg from "vue-inline-svg";
import { explorerModule } from "@/store/explorer";
import { getProductSlug, getPublicationSlug } from "@/lib/evg-shop-ext";
import { searchModule } from "@/store/search";
import { browseModule } from "@/store/browse";
import FormatListBulletedSquareIcon from "vue-material-design-icons/FormatListBulletedSquare.vue";
import store from "@/store";
export default defineComponent({
  name: "LayoutSwitch",
  components: {
    InlineSvg,
    FormatListBulletedSquareIcon
  },
  props: {
    current: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const toggleView = () => {
      if (props.current === "explorer") {
        // get the current publication or product if any, then switch to that page
        if (explorerModule.activePublication) {
          if (explorerModule.activeProduct) {
            router.push({
              name: "product",
              params: {
                permId: explorerModule.activeProduct.permId,
                slug: getProductSlug(explorerModule.activeProduct, explorerModule.activePublication)
              }
            });
          } else {
            router.push({
              name: "publication",
              params: {
                permId: explorerModule.activePublication.permId,
                slug: getPublicationSlug(explorerModule.activePublication)
              }
            });
          }
          return;
        }
        // otherwise, check whether the filters have a single type selected; then switch to that page
        if (searchModule.filters.types.length === 1) {
          const typeId = searchModule.filters.types[0];
          browseModule.updateActiveTypeById(typeId);
          const typeName = browseModule.lastActiveType;
          router.push({
            name: "byType",
            params: {
              slug: typeName
            }
          });
          return;
        }
        // otherwise go to the front page
        router.push({
          name: "Home"
        });
        return;
      } else {
        router.push({
          name: "map"
        });
      }
    };
    const isMobile = computed(() => store.getters.getIsMobile);
    return {
      toggleView,
      isMobile
    };
  }
});