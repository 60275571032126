import { Feature, MapBrowserEvent } from "ol";
import { Layer } from "ol/layer";
import { Source } from "ol/source";
import RenderFeature from "ol/render/Feature";
import * as olProj from "ol/proj";
import Geometry from "ol/geom/Geometry";

export const WGS2WM = (lng: number, lat: number): number[] => {
  /* Some latitudes have a numerical error
      that puts them a hair beyond the [-90, +90] range.
      This methods safely computes the transform
      by pegging the latitudes to that range.
  */
  return olProj.transform(
    [lng, Math.max(Math.min(lat, 90), -90)],
    "EPSG:4326",
    "EPSG:3857"
  );
};

export const WM2WGS = (x: number, y: number): number[] => {
  return olProj.transform([x, y], "EPSG:3857", "EPSG:4326");
};

export const findTopCandidate = (
  evt: MapBrowserEvent<UIEvent>,
  layerFilter?: ((arg0: Layer<Source>) => boolean) | undefined
): RenderFeature | Feature<Geometry> | undefined => {
  const candidates = evt.map.getFeaturesAtPixel(evt.pixel, {
    checkWrapped: true,
    layerFilter: layerFilter,
  });
  if (candidates.length === 0) {
    return;
  }
  candidates.sort(
    (
      one: RenderFeature | Feature<Geometry>,
      another: RenderFeature | Feature<Geometry>
    ) => {
      const aOne = one.get("sqkm");
      const aAnother = another.get("sqkm");
      if (!aOne) {
        if (!aAnother) {
          return (
            parseInt(one.get("sku").split("-")[2]) -
            parseInt(another.get("sku").split("-")[2])
          );
        }
        return 1;
      }
      if (!aAnother) {
        return -1;
      }
      return aOne - aAnother;
    }
  );
  return candidates[0];
};
