import "core-js/modules/es.array.push.js";
import { computed, defineComponent, reactive, ref, toRefs, watch, watchEffect } from "vue";
import ProductMap from "@/components/Product/Map.vue";
import DetailsSection from "@/components/Product/DetailsSection.vue";
import { Product, Publication, Publisher } from "@/lib/evg-shop";
import { apiModule } from "@/store/api";
import { useRouter } from "vue-router";
import { getProductSlug, getPublicationSlug } from "@/lib/evg-shop-ext";
import ZeroSizePlaceholder from "@/components/Product/ZeroSizePlaceholder.vue";
import { browseModule } from "@/store/browse";
import ProductTable from "@/components/ProductTable.vue";
import { explorerModule } from "@/store/explorer";
import { ElNotification as Notification } from "element-plus";
import store from "@/store";
export default defineComponent({
  name: "Product",
  components: {
    ProductTable,
    DetailsSection,
    ProductMap,
    ZeroSizePlaceholder
  },
  props: {
    productId: {
      type: String,
      required: false
    },
    publicationId: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const publication = reactive(new Publication());
    const publisher = reactive(new Publisher());
    const product = reactive(new Product());
    const bounds = ref([]);
    const router = useRouter();
    const canonicalUrl = computed(() => {
      let canonical = undefined;
      if (publication.permId) {
        canonical = `/publication/${publication.permId}/${getPublicationSlug(publication)}`;
        if (product.permId) {
          canonical = `/product/${product.permId}/${getProductSlug(product, publication)}`;
        }
      }
      return canonical;
    });
    const columns = computed(() => [{
      prop: "mapIndex"
    }, {
      prop: "title"
    }, {
      prop: "publishedDate",
      hidden: store.getters.getIsMobile
    }, {
      prop: "exposure"
    }]);
    const title = computed(() => {
      if (product && (product.title || product.mapIndex)) {
        if (product.title && product.mapIndex && product.title.toLowerCase() !== product.mapIndex.toLowerCase()) {
          return product.title + ", " + product.mapIndex;
        }
        return product.title || product.mapIndex;
      }
      return publication ? publication.name : "";
    });
    const thumbnail = computed(() => {
      if (product && product.thumbnail) {
        return `${apiModule.baseUrl}/thumbnail/${product.thumbnail}`;
      }
      return "";
    });
    const pubClient = apiModule.publicationClient;
    const publisherClient = apiModule.publisherClient;
    const prdClient = apiModule.productClient;
    const viewDetails = item => prdClient.get(item.gid).then(p => router.push({
      name: "product",
      params: {
        permId: p.permId,
        slug: getProductSlug(item, publication)
      }
    }));
    watch(() => publication.typeid, typeId => typeId && browseModule.updateActiveTypeById(typeId >> 4));
    watch(() => props.productId, () => document.querySelector("#product-anchor")?.scrollIntoView({
      behavior: "smooth"
    }));
    watch(() => publication, current => {
      explorerModule.activatePublication(current);
      explorerModule.pegToActivePublication();
    }, {
      deep: true
    });
    watch(() => product, current => {
      explorerModule.activateProductByGid(current.gid);
      explorerModule.pegToActiveProduct();
    }, {
      deep: true
    });
    watch(() => title, () => {
      document.title = `Global Explorer - ${title.value}`;
    }, {
      deep: true
    });
    const enforceCanonicalUrl = () => {
      if (canonicalUrl.value && router.currentRoute.value.fullPath !== canonicalUrl.value) {
        router.replace({
          path: canonicalUrl.value
        });
      }
    };
    const getDetails = (publicationPermId, productSku) => {
      if (!productSku) {
        product.init(new Product());
        getPublicationDetails(publicationPermId).then(() => {
          if (publication.bounds) {
            bounds.value = publication.bounds;
          }
        }).finally(enforceCanonicalUrl);
        return;
      }
      prdClient.getByPermId(productSku).then(prd => {
        getPublicationDetails(prd.publicationGid, true).then(() => {
          product.init(prd);
          if (product.bounds) {
            bounds.value = product.bounds;
          }
        }).finally(enforceCanonicalUrl);
      });
    };
    const getPublicationDetails = (publicationPermId, isGid = false) => {
      if (publicationPermId && publication && publicationPermId === (isGid ? publication.gid : getPublicationSlug(publication))) {
        return Promise.resolve();
      }
      if (!publicationPermId) {
        publication.init(new Publication());
        return Promise.reject();
      }
      const request = isGid ? pubClient.get(publicationPermId) : pubClient.getByPermId(publicationPermId);
      return request.then(pub => {
        publication.init(pub);
        if (pub.bounds) {
          bounds.value = pub.bounds;
        }
        getPublisherDetails(pub.publisherGid);
      })
      /* eslint-disable-next-line */.catch(() => {
        Notification({
          title: "Search Failed",
          message: "The requested page is not available.",
          position: "bottom-right",
          type: "error",
          duration: 2500
        });
        router.push({
          name: "Home"
        });
      });
    };
    const getPublisherDetails = publisherGid => {
      if (!publisherGid) {
        return Promise.resolve();
      }
      return publisherClient.get(publisherGid).then(publisherResponse => {
        publisher.init(publisherResponse);
        explorerModule.activatePublisher(publisherResponse);
      });
    };
    const openCopyrightAlert = () => {
      alert("Sorry, our images are copyrighted.");
    };
    watchEffect(() => getDetails(props.publicationId, props.productId));
    return {
      ...toRefs(props),
      bounds,
      columns,
      canonicalUrl,
      pubClient,
      prdClient,
      publication,
      publisher,
      product,
      title,
      thumbnail,
      viewDetails,
      openCopyrightAlert
    };
  }
});