import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/styles/index.scss";
import "./assets/snipcart.scss";
import OpenLayersMap from "vue3-openlayers";
import "vue3-openlayers/dist/vue3-openlayers.css";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

Bugsnag.start({
  apiKey: "2164b2deaaf0f7acba642a67dd576f1d",
  plugins: [new BugsnagPluginVue()],
  appVersion: "2.4.3.4",
});
const bugsnagVue = Bugsnag.getPlugin("vue");

const app = createApp(App);

if (bugsnagVue) {
  app.use(bugsnagVue);
  Bugsnag.startSession();
}

app
  // element-plus is now using unplugin
  .use(router)
  .use(store)
  .use(OpenLayersMap)
  .mount("#app");
