import { computed, defineComponent, onMounted, ref, watch } from "vue";
export default defineComponent({
  name: "EvOpenRangeSlider",
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    ticks: {
      type: Array,
      required: true
    },
    openLeft: {
      type: Boolean,
      required: false,
      default: false
    },
    openRight: {
      type: Boolean,
      required: false,
      default: false
    },
    minLabel: {
      type: String,
      required: false
    },
    maxLabel: {
      type: String,
      required: false
    },
    formatter: {
      type: Function,
      default: undefined
    }
  },
  emits: ["update:modelValue"],
  setup(props, {
    emit
  }) {
    const isOpenLeft = computed(() => props.openLeft === true || !!props.minLabel);
    const isOpenRight = computed(() => props.openRight === true || !!props.maxLabel);
    const min = computed(() => isOpenLeft.value ? -1 : 0);
    const max = computed(() => isOpenRight.value ? props.ticks.length : props.ticks.length - 1);
    const initRange = () => {
      const r = [min.value, max.value];
      if (props.modelValue.min) {
        const idx = props.ticks.findIndex(t => t === props.modelValue.min);
        if (idx !== -1) {
          r[0] = idx;
        }
      }
      if (props.modelValue.max) {
        const idx = props.ticks.findIndex(t => t === props.modelValue.max);
        if (idx !== -1) {
          r[1] = idx;
        }
      }
      range.value = r;
    };
    const range = ref([min.value, max.value]);
    const _formatter = ref(props.formatter || (n => n?.toLocaleString()));
    const formatter = ref(tick => {
      if (tick < 0) return props.minLabel || "";
      if (!(tick < props.ticks.length)) return props.maxLabel || "";
      const val = props.ticks[tick];
      return _formatter.value(val);
    });
    const marks = computed(() => {
      const mks = {};
      mks[range.value[0]] = {
        label: formatter.value(range.value[0])
      };
      mks[range.value[1]] = {
        style: "white-space: nowrap;",
        label: formatter.value(range.value[1])
      };
      return mks;
    });
    const getValueFromTick = t => {
      if (t < 0) return undefined;
      if (!(t < props.ticks.length)) return undefined;
      return props.ticks[t];
    };
    const onChange = r => {
      if (r[0] === r[1]) {
        if (r[0] === min.value) {
          range.value = [r[0], r[0] + 1];
        } else if (r[1] === max.value) {
          range.value = [r[1] - 1, r[1]];
        }
      }
      emit("update:modelValue", {
        min: getValueFromTick(r[0]),
        max: getValueFromTick(r[1])
      });
    };
    watch(() => props.modelValue, initRange);
    onMounted(initRange);
    return {
      range,
      marks,
      isOpenLeft,
      isOpenRight,
      min,
      max,
      onChange
    };
  }
});