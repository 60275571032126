import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_dms_span = _resolveComponent("dms-span");
  const _component_footer_banner = _resolveComponent("footer-banner");
  return !_ctx.isMobile ? (_openBlock(), _createBlock(_component_footer_banner, {
    key: 0
  }, {
    default: _withCtx(() => [_createTextVNode(" Lat: "), _createVNode(_component_dms_span, {
      coordinate: _ctx.center[1],
      mode: "latitude"
    }, null, 8, ["coordinate"]), _createTextVNode(" - Lng: "), _createVNode(_component_dms_span, {
      coordinate: _ctx.center[0]
    }, null, 8, ["coordinate"])]),
    _: 1
  })) : _createCommentVNode("", true);
}