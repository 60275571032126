import { computed, defineComponent, onMounted } from "vue";
import { explorerModule } from "@/store/explorer";
import store from "@/store";
/* Note: using a "drawer" from Element+ does not work:
    mouse event do not propagate to the map when the drawer is open.
 */
export default defineComponent({
  setup() {
    const sidepanelIsOpen = computed(() => explorerModule.isSideOpen);
    const isMobile = computed(() => store.getters.getIsMobile);
    onMounted(() => {
      document.title = "Global Explorer";
    });
    return {
      sidepanelIsOpen,
      isMobile
    };
  }
});