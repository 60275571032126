import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-10ad3cab"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tab-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_badge = _resolveComponent("el-badge");
  const _component_el_checkbox_button = _resolveComponent("el-checkbox-button");
  return _openBlock(), _createBlock(_component_el_checkbox_button, {
    label: _ctx.label,
    disabled: !_ctx.isActive,
    class: _normalizeClass([_ctx.isPrimary ? 'primary' : 'info']),
    onMouseover: _cache[0] || (_cache[0] = $event => _ctx.isActive && _ctx.$emit('mouse:over', _ctx.label)),
    onMouseleave: _cache[1] || (_cache[1] = $event => _ctx.isActive && _ctx.$emit('mouse:leave', _ctx.label)),
    onClick: _ctx.stopPropagation
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "content", {}, () => [_createVNode(_component_el_badge, {
      "is-dot": "",
      type: "primary",
      class: "is-in-cart",
      hidden: !_ctx.emphasize
    }, {
      default: _withCtx(() => [_ctx.iconClass ? (_openBlock(), _createBlock(_component_el_icon, {
        key: 0,
        class: _normalizeClass(_ctx.iconClass)
      }, null, 8, ["class"])) : _renderSlot(_ctx.$slots, "default", {
        key: 1
      }, undefined, true)]),
      _: 3
    }, 8, ["hidden"]), _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.titleSafe), 1)], true)]),
    _: 3
  }, 8, ["label", "disabled", "class", "onClick"]);
}