import { defineComponent, toRefs } from "vue";
export default defineComponent({
  props: {
    label: {
      type: String,
      required: true
    },
    content: {
      type: [String, Number],
      required: false
    },
    itemprop: {
      type: String,
      required: false
    }
  },
  setup(props) {
    return {
      ...toRefs(props)
    };
  }
});