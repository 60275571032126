import { computed, defineComponent, ref } from "vue";
import Drawer from "@/components/AppDrawer.vue";
import CartView from "@/views/CartView.vue";
import { snipcartModule } from "@/store/snipcart";
import CartOutlineIcon from "vue-material-design-icons/CartOutline.vue";
export default defineComponent({
  components: {
    Drawer,
    CartView,
    CartOutlineIcon
  },
  setup() {
    const itemsInCart = computed(() => snipcartModule.count);
    const showDrawer = ref(false);
    const toggleDrawer = () => {
      showDrawer.value = !showDrawer.value;
    };
    const handleClose = () => {
      showDrawer.value = false;
    };
    return {
      itemsInCart,
      showDrawer,
      toggleDrawer,
      handleClose
    };
  }
});