import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import catalogRoutes from "./catalog";
import explorerRoutes from "./explorer";

const routes: RouteRecordRaw[] = [...catalogRoutes, ...explorerRoutes];

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
