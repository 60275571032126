import {
  VuexModule,
  getModule,
  Module,
  Mutation,
} from "vuex-module-decorators";
import { IProductType, productTypes } from "@/lib/filters/Types";

@Module({ namespaced: true, name: "browse", dynamic: true, store })
export default class BrowseModule extends VuexModule {
  lastActiveType = "";

  get activeType(): string {
    return this.lastActiveType;
  }

  @Mutation
  updateActiveType(updatedType: string): void {
    const found = productTypes.find(
      (pType: IProductType) => pType.path === updatedType
    );
    if (found && found.path) {
      this.lastActiveType = found.path;
    } else {
      this.lastActiveType = "";
    }
  }

  @Mutation
  updateActiveTypeById(updatedTypeId: number): void {
    const found = productTypes.find(
      (pType: IProductType) => pType.typeid === updatedTypeId
    );
    if (found && found.path) {
      this.lastActiveType = found.path;
    }
  }
}

import store from ".";
export const browseModule = getModule(BrowseModule, store);
