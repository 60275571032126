import { getAvailableBasemaps, IBasemap } from "@/lib/basemaps";
import { IProduct, IPublication, IPublisher } from "@/lib/evg-shop";
import {
  VuexModule,
  getModule,
  Module,
  Mutation,
  Action,
} from "vuex-module-decorators";

export interface ICoordinate {
  lng: number;
  lat: number;
}

@Module({ namespaced: true, name: "explorer", dynamic: true, store })
export default class ExplorerModule extends VuexModule {
  isSidePanelOpen: boolean;
  tab: "filters" | "results" | "cart";
  center: number[];
  zoom: number;
  view: number[];
  pegged: boolean;
  mapTilerKey: string;
  highlightedPub: IPublication;
  highlightedPrd: IProduct;
  activePbn: IPublication;
  activePbr: IPublisher;
  activePrd: IProduct;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(options: any) {
    super(options);
    this.isSidePanelOpen = false;
    this.tab = "filters";
    this.center = [0, 0];
    this.zoom = 2;
    this.view = [-180, -85, 180, 85];
    this.pegged = false;
    this.mapTilerKey = (store.state as IAppState).mapTilerKey;
    this.highlightedPub = {} as IPublication;
    this.highlightedPrd = {} as IProduct;
    this.activePbn = {} as IPublication;
    this.activePbr = {} as IPublisher;
    this.activePrd = {} as IProduct;
  }

  get isSideOpen(): boolean {
    return this.isSidePanelOpen;
  }

  get activeTab(): "filters" | "results" | "cart" | undefined {
    if (this.isSidePanelOpen === true) {
      return this.tab;
    }
    return undefined;
  }

  get latestFocusPoint(): number[] {
    return this.center;
  }

  get viewBounds(): number[] {
    return this.view;
  }

  get basemapOptions(): IBasemap[] {
    return getAvailableBasemaps(this.mapTilerKey);
  }

  get highlightedPublication(): IPublication | undefined {
    return this.highlightedPub.gid ? this.highlightedPub : undefined;
  }

  get highlightedProduct(): IProduct | undefined {
    return this.highlightedPrd.gid ? this.highlightedPrd : undefined;
  }

  get activePublication(): IPublication | undefined {
    return this.activePbn.gid ? this.activePbn : undefined;
  }

  get activePublisher(): IPublisher | undefined {
    return this.activePbr.gid ? this.activePbr : undefined;
  }

  get activeProduct(): IProduct | undefined {
    return this.activePrd.gid ? this.activePrd : undefined;
  }

  @Mutation
  closeSidePanel(): void {
    this.isSidePanelOpen = false;
  }

  @Mutation
  setCurrentViewBounds(bounds: number[]): void {
    this.view = bounds;
    this.pegged = true;
  }

  @Mutation
  unpegView(): void {
    this.pegged = false;
  }

  @Mutation
  activate(tab: "filters" | "results" | "cart"): void {
    this.activePrd = {} as IProduct;
    this.activePbn = {} as IPublication;
    this.activePbr = {} as IPublisher;
    this.isSidePanelOpen = true;
    this.tab = tab;
  }

  @Mutation
  open(tab: "filters" | "results" | "cart"): void {
    this.isSidePanelOpen = true;
    this.tab = tab;
  }

  @Mutation
  toggle(tab: "filters" | "results" | "cart"): void {
    this.isSidePanelOpen = !(this.isSidePanelOpen && this.tab === tab);
    this.tab = tab;
  }

  @Mutation
  updateCenter(c: ICoordinate): void {
    this.center = [c.lng, c.lat];
  }

  @Mutation
  updateZoom(z: number): void {
    this.zoom = z;
  }

  @Mutation
  highlightPublication(publication?: IPublication): void {
    this.highlightedPub = publication || ({} as IPublication);
  }

  @Mutation
  highlightProduct(product?: IProduct): void {
    this.highlightedPrd = product || ({} as IProduct);
  }

  @Mutation
  activatePublication(publication?: IPublication): void {
    this.activePbn = publication || ({} as IPublication);
    if (
      this.activePrd.publicationGid &&
      this.activePrd.publicationGid !== this.activePbn.gid
    ) {
      explorerModule.updateProduct(undefined);
    }
    if (
      (this.activePrd.publisherGid || this.activePbn.publisherGid) !==
      this.activePbr.gid
    ) {
      explorerModule.activatePublisher(undefined);
    }
  }

  @Mutation
  activatePublisher(publisher?: IPublisher): void {
    // activate the publisher only if compatible with product/publication
    const pbr = publisher || ({} as IPublisher);
    const expectedPubGid =
      this.activePrd.publisherGid || this.activePbn?.publisherGid;
    if (pbr.gid === expectedPubGid) {
      this.activePbr = pbr;
    } else {
      this.activePbr = {} as IPublisher;
    }
  }

  @Mutation
  // activateProduct(product?: IProduct): void {
  updateProduct(product?: IProduct): void {
    this.activePrd = product || ({} as IProduct);
    explorerModule.open("results");
  }

  @Mutation
  pegToActivePublication(): void {
    if (this.activePbn.bounds) {
      this.view = this.activePbn.bounds;
      this.pegged = true;
    }
    if (this.activePbn.gid) {
      this.isSidePanelOpen = true;
      this.tab = "results";
    }
  }

  @Mutation
  pegToActiveProduct(): void {
    if (this.activePrd.bounds) {
      this.view = this.activePrd.bounds;
      this.pegged = true;
    }
    if (this.activePrd.gid) {
      this.isSidePanelOpen = true;
      this.tab = "results";
    }
  }

  @Action
  activatePublisherByGid(gid: string | undefined = undefined): Promise<void> {
    if (!gid) {
      gid =
        this.activeProduct?.publisherGid ||
        this.activePublication?.publisherGid;
    }
    if (!gid) {
      this.activatePublisher(undefined);
      return Promise.resolve();
    }
    if (gid === this.activePublisher?.gid) {
      return Promise.resolve();
    }
    return apiModule.publisherClient
      .get(gid)
      .then((p: IPublisher) => this.activatePublisher(p));
  }

  @Action
  activatePublicationByGid(gid: string | undefined): Promise<void> {
    if (!gid) {
      this.activatePublication(undefined);
      return Promise.resolve();
    }
    return apiModule.publicationClient
      .get(gid)
      .then(async (p: IPublication) => {
        this.activatePublication(p);
        await this.activatePublisherByGid();
      });
  }

  @Action
  activateProductByGid(gid: string | undefined): Promise<void> {
    if (!gid) {
      this.updateProduct(undefined);
      return Promise.resolve();
    }
    return apiModule.productClient.get(gid).then(async (p: IProduct) => {
      this.updateProduct(p);
      if (p.publicationGid !== this.activePublication?.gid) {
        await this.activatePublicationByGid(p.publicationGid);
      } else {
        await this.activatePublisherByGid();
      }
    });
  }
}

import store, { IAppState } from ".";
import { apiModule } from "./api";
export const explorerModule = getModule(ExplorerModule, store);
