import { defineComponent, ref, watch } from "vue";
import store from "../store";
export default defineComponent({
  name: "Drawer",
  props: {
    modelValue: {
      required: true,
      type: Boolean
    },
    title: {
      type: String,
      required: false
    }
  },
  computed: {
    isMobile: function () {
      return store.getters.getIsMobile;
    }
  },
  emits: ["close"],
  setup(props, {
    emit
  }) {
    const showDrawer = ref(props.modelValue);
    const heading = props.title || "";
    const handleClose = () => emit("close");
    watch(() => props.modelValue, () => {
      showDrawer.value = props.modelValue;
    });
    return {
      showDrawer,
      heading,
      handleClose
    };
  }
});