import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1a80edf0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "price"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  class: "add-to-cart"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_variant_checkbox_group = _resolveComponent("variant-checkbox-group");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_space = _resolveComponent("el-space");
  return _openBlock(), _createBlock(_component_el_space, {
    class: "add-to-cart-container",
    wrap: true,
    alignment: "flex-end"
  }, {
    default: _withCtx(() => [_createVNode(_component_variant_checkbox_group, {
      active: _ctx.exposure,
      primary: _ctx.sales,
      emphasize: _ctx.inCart,
      size: _ctx.$props.size ?? 'medium',
      selection: _ctx.selectedVariants,
      "onUpdate:selection": _cache[0] || (_cache[0] = $event => _ctx.selectedVariants = $event),
      "onMouse:over": _ctx.onMouseOver,
      "onMouse:leave": _ctx.onMouseLeave
    }, null, 8, ["active", "primary", "emphasize", "size", "selection", "onMouse:over", "onMouse:leave"]), _ctx.$props.size !== 'small' ? (_openBlock(), _createBlock(_component_el_space, {
      key: 0,
      direction: "vertical",
      class: "button-container",
      alignment: "flex-end"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_ctx.price ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.priceFormatter.format(_ctx.price)), 1)) : _createCommentVNode("", true), _ctx.variantPriceInfo ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.variantPriceInfo), 1)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_button, {
        type: _ctx.noneSelected ? 'info' : 'primary',
        disabled: _ctx.noneSelected,
        onClick: _ctx.onAddToCart
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.buttonLabel), 1)]),
        _: 1
      }, 8, ["type", "disabled", "onClick"])])]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  });
}