import "core-js/modules/es.array.push.js";
import { defineComponent, ref, watch, computed } from "vue";
import router from "@/router";
import { browseModule } from "@/store/browse";
import { productTypes } from "@/lib/filters/Types";
import Links from "./AppHeaderLinks.vue";
import AccountButton from "./AccountButton.vue";
import CartButton from "./CartButton.vue";
import LayoutSwitch from "@/components/LayoutSwitch.vue";
import SearchInput from "@/components/SearchInput.vue";
import MenuIcon from "vue-material-design-icons/Menu.vue";
export default defineComponent({
  name: "Header",
  components: {
    CartButton,
    LayoutSwitch,
    SearchInput,
    Links,
    AccountButton,
    MenuIcon
  },
  setup() {
    const activeType = ref(browseModule.activeType);
    const menuItems = productTypes;
    const onTypeClicked = () => {
      browseModule.updateActiveType(activeType.value);
      router.push({
        name: "byType",
        params: {
          slug: activeType.value
        }
      });
    };
    const configNames = ["series_in_catalog", "location"];
    const currentRoute = computed(() => {
      return router.currentRoute.value.name;
    });
    watch(() => browseModule.activeType, t => activeType.value = t);
    return {
      activeType,
      menuItems,
      onTypeClicked,
      configNames,
      currentRoute
    };
  }
});