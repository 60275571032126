import { getPublicationSlug } from "@/lib/evg-shop-ext";
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, onMounted, reactive, watch } from "vue";
import { searchModule, SearchParams } from "@/store/search";
import { default as DataTable } from "@/components/DataTable.vue";
import clone from "lodash/clone";
import isEqual from "lodash/isEqual";
import { pubModule as storeModule } from "@/store/publication";
import { ElNotification as Notification } from "element-plus";
const defaultColumns = [{
  prop: "aggregate.name",
  label: "Series Name",
  sortBy: "series",
  customizable: "series"
}, {
  prop: "aggregate.publisherName",
  label: "Publisher",
  sortBy: "publisher",
  width: 120,
  align: "center"
}, {
  prop: "aggregate.typeLabel",
  label: "Type - Subtype",
  sortBy: "type",
  width: 120,
  align: "center",
  customizable: "typeLabel"
}, {
  prop: "aggregate.scaleName",
  label: "Resolution / Scale",
  sortBy: "scale",
  width: 120,
  align: "center"
}, {
  prop: "matches",
  label: "Matches (total)",
  customizable: "matches",
  width: 80,
  align: "center"
}];
export default defineComponent({
  name: "PublicationTable",
  emits: ["publication:selected", "publication:highlighted"],
  props: {
    columns: {
      type: Array,
      required: false,
      default: () => clone(defaultColumns)
    },
    filters: {
      type: Object,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    hideLinks: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  components: {
    DataTable
  },
  methods: {
    getPublicationSlug: p => getPublicationSlug(p)
  },
  setup(props) {
    const searchParams = reactive(new SearchParams(storeModule.params));
    const table = reactive({
      isLoading: false,
      resetPage: false,
      rows: [],
      columns: props.columns
    });
    const page = computed(() => 1 + Math.floor((searchParams.offset || 0) / searchParams.limit));
    const onPaginationChanged = params => {
      searchParams.copy(params);
      table.resetPage = false;
    };
    const doSearch = () => {
      table.isLoading = true;
      searchModule.searchWithFilters({
        filters: props.filters,
        params: searchParams
      }).then(matches => {
        table.rows = matches;
      }).catch(() => {
        Notification({
          title: "Search Failed",
          message: "One or more of our services is not responding. Please try again later.",
          position: "bottom-right",
          type: "error",
          duration: 2500
        });
      }).finally(() => {
        table.isLoading = false;
        table.resetPage = false;
      });
    };
    onBeforeMount(() => {
      table.columns = props.columns.map(c => Object.assign({
        prop: c.prop
      }, defaultColumns.find(f => f.prop === c.prop), c));
      searchParams.init(storeModule.params);
      if (!isEqual(props.filters, storeModule.filters)) {
        searchParams.offset = 0;
      }
    });
    onBeforeUnmount(() => {
      storeModule.setSearchParams(searchParams);
      storeModule.updateFilters(props.filters);
    });
    onMounted(doSearch);
    watch(() => [props.filters, searchParams.limit, searchParams.sort, searchParams.order], () => {
      searchParams.offset = 0;
      table.resetPage = true;
    }, {
      deep: true
    });
    watch(() => searchParams, () => {
      doSearch();
    }, {
      deep: true
    });
    watch(() => table.resetPage, reset => {
      if (reset) {
        doSearch();
      }
    }, {
      deep: true
    });
    return {
      page,
      searchParams,
      table,
      onPaginationChanged
    };
  }
});