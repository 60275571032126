import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-043c5976"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "btn-label"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_cart_outline_icon = _resolveComponent("cart-outline-icon");
  const _component_el_button = _resolveComponent("el-button");
  const _component_add_to_cart = _resolveComponent("add-to-cart");
  const _component_data_table = _resolveComponent("data-table");
  return _openBlock(), _createBlock(_component_data_table, {
    class: "product-data-table",
    "highlight-row": true,
    "is-loading": _ctx.table.isLoading,
    "reset-page": _ctx.table.resetPage,
    columns: _ctx.viewableColumns,
    rows: _ctx.table.rows,
    total: _ctx.publication.itemCount || 0,
    sortParams: _ctx.searchParams,
    "page-size": _ctx.searchParams.limit,
    "current-row-selector": _ctx.currentRowCallback,
    onRowClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('product:selected', $event)),
    onRowHover: _cache[1] || (_cache[1] = $event => _ctx.$emit('product:highlighted', $event)),
    onPaginationChange: _ctx.onPaginationChanged
  }, {
    add_cart_header: _withCtx(() => [_renderSlot(_ctx.$slots, "add_cart_header", {}, () => [_createVNode(_component_el_button, {
      class: _normalizeClass([{
        hidden: !_ctx.hasPreselections
      }, "table-embedded-button table-header-button"]),
      type: "primary",
      onClick: _ctx.onAddToCart
    }, {
      default: _withCtx(() => [_createVNode(_component_cart_outline_icon, {
        size: 24
      }), _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.buttonLabel), 1)]),
      _: 1
    }, 8, ["class", "onClick"])], true)]),
    add_cart: _withCtx(scope => [_renderSlot(_ctx.$slots, "add_cart", {
      row: scope.row
    }, () => [_createVNode(_component_add_to_cart, {
      size: "small",
      product: scope.row,
      preselection: _ctx.preselections[scope.row.permId]?.selection,
      "onSelection:changed": _ctx.onProductSelectionChanged
    }, null, 8, ["product", "preselection", "onSelection:changed"])], true)]),
    _: 3
  }, 8, ["is-loading", "reset-page", "columns", "rows", "total", "sortParams", "page-size", "current-row-selector", "onPaginationChange"]);
}