import store from "@/store";
import { explorerModule } from "@/store/explorer";
import { defineComponent, computed } from "vue";
import DmsSpan from "./dms.vue";
import FooterBanner from "../FooterBanner.vue";
export default defineComponent({
  components: {
    DmsSpan,
    FooterBanner
  },
  setup() {
    const center = computed(() => explorerModule.latestFocusPoint || [0, 0]);
    const isMobile = computed(() => store.getters.getIsMobile);
    return {
      center,
      isMobile
    };
  }
});