import { IProduct, IPublication, IPublisher } from "./evg-shop";

const normalizeSlug = (slug: string): string => {
  const normSlug = (slug || "")
    // GEOW-529 Safari does not support lookbehind regexp
    .replace(/(\d),(\d)/g, "$1$2")
    .replace(/1:(\d+)/, "$1")
    .replace(/\s|[()[\]{}$%&]/g, "-")
    .replace(/-+$/g, "")
    .replace(/'/g, "")
    .replace(/-+/g, "-");
  return normSlug;
};

export const getPublicationSlug = (p: IPublication): string => {
  return p.slug ?? p.sku;
};

export const getPublisherSlug = (p: IPublisher): string => {
  // Temporary fix; follow-up via GEOW-795
  return normalizeSlug([p.name, p.fullname].join("-"));
};

export const getProductSlug = (prd: IProduct, pub: IPublication): string => {
  return `${prd.slug ?? prd.sku}-${getPublicationSlug(pub)}`;
};

export const scaleValues = [
  1000, 2000, 5000, 12500, 25000, 50000, 100000, 250000, 500000, 1000000,
  2000000, 5000000,
];
// export const scaleValues = [
//   1, 2, 5, 12.5, 25, 50, 100, 250, 500, 1000, 2000, 5000,
// ].map((v: number) => Math.round(v * 1000));

export const dateValues = [
  ...new Array(Math.ceil((new Date().getFullYear() - 1940) / 10)),
].map((_, idx) => 1940 + idx * 10);

export type Variants = {
  print: boolean;
  image: boolean;
  data: boolean;
};

export type VariantLabel = "Paper" | "Image" | "Data";

export const noneVariant: Variants = {
  print: false,
  image: false,
  data: false,
};
export const allVariants: Variants = { print: true, image: true, data: true };

export const flagToVariants = (f?: number): Variants => ({
  print: checkFlag(0, f) || checkFlag(3, f),
  image: checkFlag(1, f),
  data: checkFlag(2, f),
});

export const variantsToFlag = (v: Variants): number =>
  (v.print ? 9 : 0) + (v.image ? 2 : 0) + (v.data ? 4 : 0);

export const checkFlag = (v: number, f?: number): boolean =>
  (((f ?? 0) >> v) & 1) === 1;

export const defaultRowsPerPage =
  parseInt(process.env.VUE_APP_DEFAULT_ROWS_PER_PAGE) || 25;
