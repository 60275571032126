import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "series-label"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  class: "type-label-composite"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  const _component_data_table = _resolveComponent("data-table");
  return _openBlock(), _createBlock(_component_data_table, {
    class: "publication-table",
    "highlight-row": true,
    "is-loading": _ctx.table.isLoading,
    "reset-page": _ctx.table.resetPage,
    "initial-page": _ctx.page,
    columns: _ctx.table.columns,
    rows: _ctx.table.rows,
    total: _ctx.total,
    sortParams: _ctx.searchParams,
    "page-size": _ctx.searchParams.limit,
    onRowClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('publication:selected', $event)),
    onRowHover: _cache[1] || (_cache[1] = $event => _ctx.$emit('publication:highlighted', $event)),
    onPaginationChange: _ctx.onPaginationChanged
  }, {
    series: _withCtx(scope => [!_ctx.hideLinks ? (_openBlock(), _createBlock(_component_router_link, {
      key: 0,
      to: {
        name: 'publication',
        params: {
          permId: scope.row.aggregate.permId,
          slug: _ctx.getPublicationSlug(scope.row.aggregate)
        }
      },
      class: "publication-link"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(scope.row.aggregate.name), 1)]),
      _: 2
    }, 1032, ["to"])) : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(scope.row.aggregate.name), 1))]),
    matches: _withCtx(scope => [_createElementVNode("div", null, _toDisplayString(scope.row.matches), 1), scope.row.matches !== scope.row.aggregate.itemCount ? (_openBlock(), _createElementBlock("div", _hoisted_2, " (" + _toDisplayString(scope.row.aggregate.itemCount.toLocaleString()) + ") ", 1)) : _createCommentVNode("", true)]),
    typeLabel: _withCtx(scope => [_createElementVNode("span", _hoisted_3, _toDisplayString([scope.row.aggregate.typeLabel, scope.row.aggregate.subtypeLabel].filter(lbl => !!lbl).join(" - ")), 1)]),
    _: 1
  }, 8, ["is-loading", "reset-page", "initial-page", "columns", "rows", "total", "sortParams", "page-size", "onPaginationChange"]);
}