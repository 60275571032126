import { defineComponent, onMounted, ref } from "vue";
import { ElNotification as Notification } from "element-plus";
export default defineComponent({
  setup() {
    const placeholder = ref();
    const isZeroSized = ref(true);
    const maxSizeChecks = 10;
    const checkSize = attempt => {
      if (attempt > maxSizeChecks) {
        Notification({
          title: "Application timed out",
          message: "Functionality on this page may be limited.",
          position: "bottom-right",
          type: "warning",
          duration: 2500
        });
        return;
      }
      const p = placeholder.value;
      if (p) {
        if (p?.clientHeight > 0 && p?.clientWidth > 0) {
          isZeroSized.value = false;
        }
      }
      if (isZeroSized.value !== false) {
        setTimeout(() => checkSize(attempt + 1), 10);
      }
    };
    onMounted(() => {
      checkSize(0);
    });
    return {
      isZeroSized,
      placeholder
    };
  }
});