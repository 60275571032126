import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7cf1a360"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "pub-type-menu"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");
  const _component_router_link = _resolveComponent("router-link");
  const _component_search_input = _resolveComponent("search-input");
  const _component_links = _resolveComponent("links");
  const _component_account_button = _resolveComponent("account-button");
  const _component_cart_button = _resolveComponent("cart-button");
  const _component_menu_icon = _resolveComponent("menu-icon");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_popover = _resolveComponent("el-popover");
  const _component_el_space = _resolveComponent("el-space");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_el_tabs = _resolveComponent("el-tabs");
  const _component_el_container = _resolveComponent("el-container");
  const _component_layout_switch = _resolveComponent("layout-switch");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_container, {
    direction: "vertical",
    class: "header-container"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_row, {
      class: "app-bar-container"
    }, {
      default: _withCtx(() => [_createVNode(_component_router_link, {
        to: {
          path: '/'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_image, {
          class: "logo",
          src: require('@/assets/images/logo.svg'),
          fit: "contain",
          alt: "East View Geospatial"
        }, null, 8, ["src"])]),
        _: 1
      }), _ctx.currentRoute !== 'Home' ? (_openBlock(), _createBlock(_component_search_input, {
        key: 0,
        "config-names": _ctx.configNames
      }, null, 8, ["config-names"])) : _createCommentVNode("", true), _createVNode(_component_el_space, {
        prefixCls: "top-right-bar"
      }, {
        default: _withCtx(() => [_createVNode(_component_links, {
          class: "nav-link-container"
        }), _createVNode(_component_account_button), _createVNode(_component_cart_button), _createVNode(_component_el_popover, {
          placement: "bottom",
          trigger: "click",
          width: "fit-content",
          offset: -4
        }, {
          reference: _withCtx(() => [_createVNode(_component_el_button, {
            class: "header-button menu-button",
            circle: "",
            plain: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_menu_icon, {
              size: 24
            })]),
            _: 1
          })]),
          default: _withCtx(() => [_createVNode(_component_links, {
            class: "nav-link-container"
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_tabs, {
      modelValue: _ctx.activeType,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.activeType = $event),
      onTabClick: _ctx.onTypeClicked
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, idx) => {
        return _openBlock(), _createBlock(_component_el_tab_pane, {
          key: idx,
          label: item.label,
          name: item.path
        }, null, 8, ["label", "name"]);
      }), 128))]),
      _: 1
    }, 8, ["modelValue", "onTabClick"])])]),
    _: 1
  }), _createVNode(_component_layout_switch, {
    current: "catalog"
  })], 64);
}