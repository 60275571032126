import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_aside = _resolveComponent("el-aside");
  const _component_el_main = _resolveComponent("el-main");
  const _component_el_container = _resolveComponent("el-container");
  const _component_router_link = _resolveComponent("router-link");
  return !_ctx.isMobile ? (_openBlock(), _createBlock(_component_el_container, {
    key: 0,
    class: "fullviewport",
    style: {
      "position": "relative"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_aside, {
      id: "sidebar-drawer",
      class: _normalizeClass({
        'is-open': _ctx.sidepanelIsOpen
      })
    }, {
      default: _withCtx(() => [_createVNode(_component_router_view, {
        name: "left-side-drawer"
      })]),
      _: 1
    }, 8, ["class"]), _createVNode(_component_el_main, {
      class: "fullviewport content"
    }, {
      default: _withCtx(() => [_createVNode(_component_router_view)]),
      _: 1
    })]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_el_container, {
    key: 1,
    class: "hide-map-message"
  }, {
    default: _withCtx(() => [_createElementVNode("p", null, [_createTextVNode(" The Global Explorer is designed for larger screens and desktop computer. Please "), _createVNode(_component_router_link, {
      to: "/"
    }, {
      default: _withCtx(() => [_createTextVNode("browse our catalog instead")]),
      _: 1
    })])]),
    _: 1
  }));
}