export interface IProductType {
  typeid: number;
  label: string;
  path?: string;
  title: string;
  shown?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  image?: any;
  color?: string;
}

export const productTypes: Array<IProductType> = [
  {
    typeid: 1,
    label: "Topographic",
    title: "TOPOGRAPHIC MAPS",
    path: "topographic",
    image: require("@/assets/images/topographic.jpg"),
    color: "#96a6a1",
  },
  {
    typeid: 2,
    label: "Nautical",
    title: "NAUTICAL/HYDROGRAPHIC CHARTS",
    path: "nautical",
    image: require("@/assets/images/nautical.jpg"),
    color: "#81acbf",
  },
  {
    typeid: 3,
    label: "Geoscientific",
    title: "GEOSCIENTIFIC MAPS",
    path: "geoscientific",
    image: require("@/assets/images/geoscientific.jpg"),
    color: "#c5b184",
  },
  {
    typeid: 9,
    label: "Aeronautical",
    title: "AERONAUTICAL CHARTS",
    path: "aeronautical",
    image: require("@/assets/images/aeronautical.jpg"),
    color: "#b1b1b1",
  },
  {
    typeid: 4,
    label: "Thematic",
    title: "THEMATIC MAPPING",
    path: "thematic",
    image: require("@/assets/images/thematic.jpg"),
    color: "#c7b299",
  },
  {
    typeid: 7,
    label: "Elevation",
    title: "TERRAIN & ELEVATION MODELS",
    path: "elevation",
    image: require("@/assets/images/elevation.jpg"),
    color: "#c7cecc",
  },
];
