import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_cart_outline_icon = _resolveComponent("cart-outline-icon");
  const _component_el_badge = _resolveComponent("el-badge");
  const _component_cart_view = _resolveComponent("cart-view");
  const _component_drawer = _resolveComponent("drawer");
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createBlock(_component_el_button, {
    class: "header-button",
    circle: "",
    plain: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_el_badge, {
      class: "left-badge",
      type: "primary",
      max: 99,
      value: _ctx.itemsInCart,
      hidden: !(_ctx.itemsInCart > 0),
      onClick: _ctx.toggleDrawer
    }, {
      default: _withCtx(() => [_createVNode(_component_cart_outline_icon, {
        size: 24
      })]),
      _: 1
    }, 8, ["value", "hidden", "onClick"]), (_openBlock(), _createBlock(_Teleport, {
      to: "#cart-drawer"
    }, [_createVNode(_component_drawer, {
      modelValue: _ctx.showDrawer,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.showDrawer = $event),
      title: "Cart items",
      onClose: _ctx.handleClose
    }, {
      default: _withCtx(() => [_ctx.showDrawer ? (_openBlock(), _createBlock(_component_cart_view, {
        key: 0,
        onClose: _ctx.handleClose
      }, null, 8, ["onClose"])) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["modelValue", "onClose"])]))]),
    _: 1
  });
}