import { computed, defineComponent, toRefs } from "vue";
import AddToCart from "@/components/AddToCart.vue";
import DetailsRow from "./DetailsRow.vue";
import { ElNotification as Notification } from "element-plus";
import { cleanupName, countries } from "@/lib/filters/Countries";
import { apiModule } from "@/store/api";
import { snipcartModule } from "@/store/snipcart";
import { flagToVariants, getPublicationSlug, getPublisherSlug } from "@/lib/evg-shop-ext";
export default defineComponent({
  name: "DetailsSection",
  components: {
    AddToCart,
    DetailsRow
  },
  props: {
    product: {
      type: Object,
      required: false
    },
    publication: {
      type: Object,
      required: true
    },
    publisher: {
      type: Object,
      required: false
    }
  },
  setup(props) {
    const productId = computed(() => props.product?.permId);
    const region = computed(() => {
      const country = countries.find(c => props.publication.covcountry === c.name);
      return country ? country.regions.join(", ") : props.publication.covcountry;
    });
    const covcountries = computed(() => {
      if (!(productId.value && props.product && props.product.countries && props.product.countries.length > 0)) {
        return [];
      }
      return countries.filter(c => props.product?.countries?.includes(c.code)).map(c => {
        c.name = cleanupName(c.name);
        return c;
      });
    });
    const countryNames = computed(() => {
      return covcountries.value.map(c => c.name).join(",");
    });
    const title = computed(() => {
      if (props.product && (props.product.title || props.product.mapIndex)) {
        return props.product.title || props.product.mapIndex;
      }
      return props.publication ? props.publication.name : "";
    });
    const thumbnail = computed(() => {
      if (props.product && props.product.thumbnail) {
        return `${apiModule.baseUrl}/thumbnail/${props.product.thumbnail}`;
      }
      return "";
    });
    const scaleName = computed(() => props.product?.scaleName || props.publication?.scaleName);
    const itemPropType = computed(() => {
      let t = [props.publication.typeLabel, props.publication.subtypeLabel];
      if (props.product && props.product.gid) {
        t = [props.product.typeLabel, props.product.subtypeLabel];
      }
      return t.filter(s => !!s).join(" - ");
    });
    const publicationYearRange = computed(() => {
      if (props.publication?.years && (props.publication.years.min || props.publication.years.max)) {
        if (props.publication.years.min && props.publication.years.max && props.publication.years.min !== props.publication.years.max) {
          return `${props.publication.years.min} - ${props.publication.years.max}`;
        }
        return (props.publication.years.min || props.publication.years.max)?.toString();
      }
      return undefined;
    });
    const description = computed(() => {
      if (props.product && props.product.gid) {
        return undefined;
      }
      let d = "Spatial coverage index compiled by East View Geospatial";
      if (props.publication.name) {
        d += ` of set "${props.publication.name}".`;
      }
      if (props.publication.publisherName) {
        d += ` Source data from ${props.publication.publisherName} (publisher).`;
      }
      d += ` Type: ${itemPropType.value}. Scale: ${scaleName.value}.`;
      if (region.value) {
        d += ` Region: ${region.value}.`;
      }
      if (countryNames.value.length) {
        d += `Countries: ${countryNames.value}.`;
      }
      return d;
    });
    const warnings = computed(() => {
      if (preselection.value !== undefined && props.product?.sales !== undefined && props.product?.inventory === "Procurable") {
        const sales = flagToVariants(props.product.sales);
        if (preselection.value?.print && sales.print || preselection.value?.image && sales.image || preselection.value?.data && sales.data) {
          return ["This item may delay your order while we procure it from the publisher."];
        }
      }
      return undefined;
    });
    const onAddSingleToCart = (product, selection) => {
      snipcartModule.addSingleToCart({
        product,
        selection,
        seriesName: props.publication.name
      }).then(() => Notification({
        title: "Item added to cart",
        message: `item ${product.sku} was successfully added into the cart.`,
        position: "top-right",
        offset: 100,
        type: "info"
      })).catch(() => Notification({
        title: "Failed to add item to cart",
        message: "Please try again. If the problem persists, contact us via email: evg_web_support@eastview.com",
        position: "top-right",
        offset: 100,
        type: "error"
      }));
    };
    const onSelectionChanged = (p, selection) => {
      snipcartModule.select({
        product: p,
        selection,
        seriesName: props.publication.name
      });
    };
    const preselection = computed(() => snipcartModule.getPreselection(productId.value));
    return {
      ...toRefs(props),
      description,
      covcountries,
      countryNames,
      publicationYearRange,
      preselection,
      productId,
      region,
      scaleName,
      title,
      thumbnail,
      itemPropType,
      warnings,
      onAddSingleToCart,
      onSelectionChanged,
      getPublicationSlug,
      getPublisherSlug
    };
  }
});