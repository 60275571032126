import { defineComponent, onMounted } from "vue";
import { productTypes } from "@/lib/filters/Types";
import SearchInput from "@/components/SearchInput.vue";
import { browseModule } from "@/store/browse";
export default defineComponent({
  name: "Home",
  components: {
    SearchInput
  },
  setup() {
    const productsCategory = productTypes;
    onMounted(() => {
      browseModule.updateActiveType("");
      document.title = "Global Explorer - Main Page";
    });
    return {
      productsCategory
    };
  }
});