import { computed, defineComponent, onBeforeMount, onBeforeUnmount, onMounted, reactive, watch } from "vue";
import DataTable from "@/components/DataTable.vue";
import { SearchParams } from "@/store/search";
import clone from "lodash/clone";
import isEqual from "lodash/isEqual";
import { prodModule as storeModule } from "@/store/publication";
import { apiModule } from "@/store/api";
import AddToCart from "./AddToCart.vue";
import { snipcartModule } from "@/store/snipcart";
import { ElNotification as Notification } from "element-plus";
import CartOutlineIcon from "vue-material-design-icons/CartOutline.vue";
const defaultColumns = [{
  prop: "mapIndex",
  label: "Sheet#",
  sortBy: "map_index",
  align: "center"
}, {
  prop: "title",
  label: "Sheet Name",
  sortBy: "title",
  align: "center"
}, {
  prop: "publishedDate",
  label: "published",
  sortBy: "publishedDate",
  align: "center"
}, {
  prop: "exposure",
  label: "Add to cart",
  customizable: "add_cart",
  align: "center"
}];
export default defineComponent({
  name: "ProductTable",
  emits: ["product:selected", "product:highlighted"],
  props: {
    productSku: {
      type: String,
      required: false
    },
    publication: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      required: false,
      default: () => clone(defaultColumns)
    },
    filters: {
      type: Object,
      required: false
    },
    total: {
      type: Number,
      required: true
    }
  },
  components: {
    AddToCart,
    CartOutlineIcon,
    DataTable
  },
  setup(props) {
    const searchParams = reactive(new SearchParams(storeModule.params));
    const searchClient = apiModule.searchClient;
    const table = reactive({
      isLoading: false,
      resetPage: false,
      rows: [],
      columns: props.columns
    });
    const viewableColumns = computed(() => table.columns.filter(c => c.hidden !== true));
    const onPaginationChanged = params => {
      searchParams.copy(params);
      table.resetPage = false;
    };
    const doSearch = () => {
      if (!props.publication.gid) {
        return;
      }
      table.isLoading = true;
      searchClient.getProductsForPublication(props.publication.gid, searchParams.sort, searchParams.order, searchParams.limit, searchParams.offset).then(results => {
        table.rows = results;
      }).catch(() => {
        Notification({
          title: "Search Failed",
          message: "One or more of our services is not responding. Please try again later.",
          position: "bottom-right",
          type: "error",
          duration: 2500
        });
      }).finally(() => {
        table.isLoading = false;
        table.resetPage = false;
      });
    };
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const ids = computed(() => table.rows.map(p => p.permId));
    const hasPreselections = computed(() => {
      const keys = snipcartModule.selected;
      return ids.value.findIndex(id => id && keys.includes(id)) > -1;
    });
    const toFlags = v => {
      if (!v) return 0;
      let f = 0;
      if (v.print) f += 9;
      if (v.image) f += 2;
      if (v.data) f += 4;
      return f;
    };
    const hasPurchasable = computed(() => {
      const keys = snipcartModule.selected;
      return ids.value.filter(id => id && keys.includes(id)).map(id => snipcartModule.preSelections[id]).some(ps => ((ps.product.sales ?? 0) & toFlags(ps.selection)) > 0);
    });
    const buttonLabel = computed(() => hasPurchasable.value ? "Add To Cart" : "Ask US");
    const preselections = computed(() => snipcartModule.preSelections);
    const onAddToCart = () => {
      snipcartModule.addManyToCart(ids.value).then(r => {
        if (r.success && r.success.length > 0) {
          let message = "";
          if (r.success.length === 1) {
            message = `One item was successfully added into the cart (SKU: ${r.success[0]}).`;
          } else {
            message = `${r.success.length} items were successfully added into the cart.`;
          }
          Notification({
            title: "Added to cart",
            message,
            position: "top-right",
            offset: 100,
            type: "info"
          });
        }
        if (r.skipped && r.skipped.length > 0) {
          setTimeout(skipped => {
            let message = "";
            if (skipped.length === 1) {
              message += `One item was already in the cart (SKU: ${skipped[0]}); it was not added again.`;
            } else {
              message += `${skipped.length} items were already in the cart; they were not added again.`;
            }
            Notification({
              title: "Added to cart",
              message,
              position: "top-right",
              offset: 100,
              type: "warning"
            });
          }, 10, r.skipped);
        }
      }).catch(() => Notification({
        title: "Failed to add item to cart",
        message: "Please try again. If the problem persists, contact us via email: evg_web_support@eastview.com",
        position: "top-right",
        offset: 100,
        type: "error"
      }));
    };
    const onProductSelectionChanged = (p, selection) => {
      snipcartModule.select({
        product: p,
        selection,
        seriesName: props.publication.name
      });
    };
    const currentRowCallback = p => p.sku !== undefined && p.sku === props.productSku;
    const mergeColumnProps = () => {
      table.columns = props.columns.map(c => Object.assign({
        prop: c.prop
      }, defaultColumns.find(f => f.prop === c.prop), c));
    };
    onBeforeMount(() => {
      mergeColumnProps();
      searchParams.init(storeModule.params);
      if (!isEqual(props.filters, storeModule.filters)) {
        searchParams.offset = 0;
      }
    });
    onBeforeUnmount(() => {
      storeModule.setSearchParams(searchParams);
    });
    onMounted(doSearch);
    watch(() => [props.filters, props.publication.gid, searchParams.limit, searchParams.sort, searchParams.order], () => {
      searchParams.offset = 0;
      table.resetPage = true;
    }, {
      deep: true
    });
    watch(() => searchParams, () => {
      doSearch();
    }, {
      deep: true
    });
    watch(() => table.resetPage, reset => {
      if (reset) {
        doSearch();
      }
    }, {
      deep: true
    });
    watch(() => props.columns, mergeColumnProps, {
      deep: true
    });
    return {
      buttonLabel,
      hasPreselections,
      preselections,
      searchParams,
      viewableColumns,
      table,
      currentRowCallback,
      onPaginationChanged,
      onProductSelectionChanged,
      onAddToCart
    };
  }
});