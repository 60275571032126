import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_slider = _resolveComponent("el-slider");
  return _openBlock(), _createBlock(_component_el_slider, {
    class: "ev-slider",
    range: "",
    "show-tooltip": false,
    modelValue: _ctx.range,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.range = $event),
    min: _ctx.min,
    max: _ctx.max,
    step: 1,
    marks: _ctx.marks,
    onChange: _ctx.onChange
  }, null, 8, ["modelValue", "min", "max", "marks", "onChange"]);
}