import "core-js/modules/es.array.push.js";
import { computed, defineComponent } from "vue";
import CartViewItemCard from "@/components/CartViewItemCard.vue";
import { snipcartModule } from "@/store/snipcart";
import uniq from "lodash/uniq";
import store from "@/store";
export default defineComponent({
  components: {
    CartViewItemCard
  },
  emits: ["close"],
  setup(_, {
    emit
  }) {
    const snipcartItems = computed(() => snipcartModule.items);
    const rfpSeriesNames = computed(() => uniq(snipcartModule.rfpItems.map(item => item.metadata?.seriesName ?? "")));
    const purchaseSeriesNames = computed(() => uniq(snipcartModule.purchaseItems.map(item => item.metadata?.seriesName ?? "")));
    const purchaseItems = computed(() => snipcartModule.purchaseItems);
    const rfpProducts = computed(() => snipcartModule.rfpItems);
    const checkoutButtonText = computed(() => {
      const labels = [];
      if (purchaseItems.value.length > 0) {
        labels.push("Checkout");
      }
      if (rfpProducts.value.length > 0) {
        labels.push("Submit Request");
      }
      return labels.join(" & ");
    });
    const isEmpty = computed(() => snipcartModule.items.length === 0);
    const handleClose = () => {
      store.commit("setIsRfpModalOpen", false);
      emit("close");
    };
    const handleCheckout = async () => {
      if (purchaseItems.value.length > 0) {
        window.location.href = "#/checkout";
      } else if (rfpProducts.value.length > 0) {
        store.commit("setIsRfpModalOpen", true);
      }
      emit("close");
    };
    return {
      purchaseItems,
      rfpProducts,
      rfpSeriesNames,
      purchaseSeriesNames,
      snipcartItems,
      checkoutButtonText,
      isEmpty,
      handleClose,
      handleCheckout
    };
  }
});