import { computed, defineComponent } from "vue";
import EvgSourceXyz from "@/lib/vue3-openlayers-ext/components/sources/SourceXYZ.vue";
import { explorerModule } from "@/store/explorer";
export default defineComponent({
  components: {
    EvgSourceXyz
  },
  props: {
    base: {
      type: String,
      required: false,
      default: "mt-positron"
    }
  },
  setup(props) {
    const basemaps = computed(() => explorerModule.basemapOptions);
    const basemap = computed(() => basemaps.value.find(b => b.title === props.base) || basemaps.value[0]);
    return {
      basemap
    };
  }
});