import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import store from "@/store";
import MapHeader from "@/components/explorer/Header.vue";
import MapFooter from "@/components/explorer/Footer.vue";
import Explorer from "@/views/Explorer.vue";
const ExplorerCore = () =>
  import(
    /* webpackChunkName: "explorer-core" */ "@/components/explorer/Core.vue"
  );
const SidebarMain = () =>
  import(
    /* webpackChunkName: "explorer-side" */ "@/components/sidebar/Main.vue"
  );

export default [
  {
    path: "/explore",
    name: "Global Explorer",
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ): void => {
      store.commit("setLayout", "explorer");
      next();
    },
    components: {
      default: Explorer,
      header: MapHeader,
      footer: MapFooter,
    },
    children: [
      {
        path: "/map",
        name: "map",
        components: {
          default: ExplorerCore,
          "left-side-drawer": SidebarMain,
        },
      },
    ],
  },
] as RouteRecordRaw[];
