import { computed, defineComponent } from "vue";
import SearchInput from "@/components/SearchInput.vue";
import AccountButton from "@/components/AccountButton.vue";
import CartButton from "@/components/CartButton.vue";
import store from "@/store";
export default defineComponent({
  components: {
    AccountButton,
    CartButton,
    SearchInput
  },
  name: "Header",
  setup() {
    const isMobile = computed(() => store.getters.getIsMobile);
    return {
      isMobile
    };
  }
});