import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-48536a36"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 1,
  id: "toolbar",
  class: "el-space el-space--horizontal"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");
  const _component_router_link = _resolveComponent("router-link");
  const _component_search_input = _resolveComponent("search-input");
  const _component_cart_button = _resolveComponent("cart-button");
  const _component_account_button = _resolveComponent("account-button");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_container = _resolveComponent("el-container");
  return _openBlock(), _createBlock(_component_el_container, {
    direction: "vertical",
    class: "header-container"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_row, {
      class: "app-bar-container"
    }, {
      default: _withCtx(() => [_createVNode(_component_router_link, {
        to: {
          path: '/'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_image, {
          class: "logo",
          src: require('@/assets/images/logo.svg'),
          fit: "contain",
          alt: "East View Geospatial"
        }, null, 8, ["src"])]),
        _: 1
      }), !_ctx.isMobile ? (_openBlock(), _createBlock(_component_search_input, {
        key: 0
      })) : _createCommentVNode("", true), !_ctx.isMobile ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_cart_button, {
        class: "tool-button cart-button"
      }), _createVNode(_component_account_button, {
        class: "tool-button account-button"
      })])) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  });
}