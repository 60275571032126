import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "layout-switch"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_inline_svg = _resolveComponent("inline-svg");
  const _component_format_list_bulleted_square_icon = _resolveComponent("format-list-bulleted-square-icon");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_tooltip = _resolveComponent("el-tooltip");
  return !_ctx.isMobile ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_tooltip, {
    "popper-class": "toolbar-tip",
    placement: _ctx.current === 'catalog' ? 'top' : 'right',
    effect: "light"
  }, {
    content: _withCtx(() => [_createTextVNode(" Switch to " + _toDisplayString(_ctx.current === "catalog" ? "Map" : "Catalog") + " View ", 1)]),
    default: _withCtx(() => [_createVNode(_component_el_button, {
      type: "info",
      class: "tool-button",
      onClick: _ctx.toggleView
    }, {
      default: _withCtx(() => [_ctx.current === 'catalog' ? (_openBlock(), _createBlock(_component_inline_svg, {
        key: 0,
        class: "svg-icon",
        src: require('@/assets/icons/global-explorer.svg')
      }, null, 8, ["src"])) : (_openBlock(), _createBlock(_component_format_list_bulleted_square_icon, {
        key: 1
      }))]),
      _: 1
    }, 8, ["onClick"])]),
    _: 1
  }, 8, ["placement"])])) : _createCommentVNode("", true);
}