import { computed, defineComponent, onBeforeUnmount, onMounted, watch } from "vue";
import store from "./store";
import { searchModule } from "@/store/search";
import { ElNotification as Notification } from "element-plus";
import { MOBILE_MAX_WIDTH } from "./constants";
import { snipcartModule } from "./store/snipcart";
import RfpModal from "./views/RFPModal.vue";
import Bugsnag from "@bugsnag/js";
export default defineComponent({
  name: "App",
  components: {
    RfpModal
  },
  setup() {
    const layout = computed(() => store.getters.getLayout);
    searchModule.initializeAvailableFilters().catch(() => Notification({
      title: "Initialization Failed",
      message: "One or more of our services is not responding. Please try again later.",
      position: "bottom-right",
      type: "error",
      duration: 2500
    }));
    const onResize = () => {
      const isMobile = window.innerWidth < MOBILE_MAX_WIDTH;
      store.commit("setIsMobile", isMobile);
    };
    onMounted(() => {
      window.addEventListener("resize", onResize, {
        passive: true
      });
      Bugsnag.leaveBreadcrumb("App mounted");
    });
    onBeforeUnmount(() => window.removeEventListener("resize", onResize));
    watch(() => snipcartModule.items, (items, prevItems) => {
      const lastPurchaseItemRemoved = items.filter(i => !i.metadata.isRfp).length === 0 && prevItems.filter(i => !i.metadata.isRfp).length > 0;
      if (lastPurchaseItemRemoved) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        snipcartModule.sdk_.api.theme.cart.close();
      }
    });
    return {
      layout
    };
  },
  mounted() {
    window.addEventListener("resize", this.onResize, {
      passive: true
    });
  },
  methods: {
    onResize() {
      const isMobile = window.innerWidth < MOBILE_MAX_WIDTH;
      store.commit("setIsMobile", isMobile);
    }
  }
});