import "core-js/modules/es.array.push.js";
import { defineComponent, reactive, computed, watch, ref } from "vue";
import { defaultRowsPerPage } from "@/lib/evg-shop-ext";
import store from "../store";
export default defineComponent({
  name: "DataTable",
  emits: ["pagination-change", "row-click", "row-hover"],
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    resetPage: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: ""
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      }
    },
    rows: {
      type: Array,
      default: () => {
        return [];
      }
    },
    currentRowSelector: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Function,
      required: false
    },
    pageSize: {
      type: Number,
      default: defaultRowsPerPage
    },
    total: {
      type: Number,
      default: 100
    },
    initialPage: {
      type: Number,
      default: 1
    },
    highlightRow: {
      type: Boolean,
      default: false
    },
    sortParams: {
      type: Object,
      default: () => {
        return {
          order: "asc",
          sort: "not_provided"
        };
      }
    }
  },
  setup(props, {
    emit
  }) {
    const settings = reactive({
      page: Math.max(1, props.initialPage),
      pageSize: props.pageSize,
      pageSizeOptions: [10, 25, 50],
      total: props.total,
      maxPage: computed(() => {
        if (props.total <= 0) {
          return 0;
        }
        let maxPage = Math.floor(props.total / settings.pageSize);
        let mod = props.total % settings.pageSize;
        if (mod > 0) {
          maxPage++;
        }
        return maxPage;
      }),
      offset: computed(() => {
        return (settings.page - 1) * settings.pageSize + 1;
      }),
      limit: computed(() => {
        let limit = settings.page * settings.pageSize;
        return props.total >= limit ? limit : props.total;
      }),
      paging: computed(() => {
        let startPage = settings.page - 2 <= 0 ? 1 : settings.page - 2;
        if (settings.maxPage - settings.page <= 2) {
          startPage = settings.maxPage - 4;
        }
        startPage = startPage <= 0 ? 1 : startPage;
        let pages = [];
        for (let i = startPage; i <= settings.maxPage; i++) {
          if (pages.length < 5) {
            pages.push(i);
          }
        }
        return pages;
      }),
      order: props.sortParams.order,
      sortBy: props.sortParams.sort
    });
    const localRows = ref(props.rows);
    const showPagination = computed(() => props.total > Math.min(...settings.pageSizeOptions));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rowClassSelector = v => {
      if (props.currentRowSelector && props.currentRowSelector(v.row)) {
        return "current-row";
      }
      return "";
    };
    const onSortChanged = e => {
      let order = undefined;
      if (e.order === "descending") {
        order = "desc";
      }
      if (e.order === "ascending") {
        order = "asc";
      }
      let sortBy = e.column?.sortBy;
      if (!sortBy) {
        const idx = e.column?.getColumnIndex();
        if (idx && props.columns && props.columns.length > idx) {
          sortBy = props.columns[idx].sortBy;
        }
      }
      settings.sortBy = sortBy;
      settings.order = order;
      const offset = Math.max(0, settings.page - 1) * settings.pageSize;
      emit("pagination-change", {
        offset,
        limit: settings.pageSize,
        sort: settings.sortBy,
        order: settings.order
      });
    };
    const onPageChanged = page => {
      settings.page = page;
      const offset = Math.max(0, settings.page - 1) * settings.pageSize;
      emit("pagination-change", {
        offset,
        limit: settings.pageSize,
        sort: settings.sortBy,
        order: settings.order
      });
    };
    const onPageSizeChanged = size => {
      //current offset
      const offset = Math.max(0, settings.page - 1) * settings.pageSize;
      settings.pageSize = size;
      // page of the current offset based on the new size
      onPageChanged(Math.floor(offset / size) + 1);
    };
    watch(() => props.resetPage, () => {
      if (props.resetPage) {
        settings.page = 1;
      }
    });
    /* eslint-disable-next-line */
    const stringFormat = (template, ...args) => {
      return template.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != "undefined" ? args[number] : match;
      });
    };
    const onRowClick = row => emit("row-click", row);
    const onMouseEnter = row => emit("row-hover", row);
    const onMouseLeave = () => emit("row-hover", undefined);
    const currentSort = computed(() => {
      const col = props.columns.find(c => c.sortBy === props.sortParams?.sort);
      if (!(props.sortParams && col)) {
        return {};
      }
      return {
        prop: col.prop,
        order: props.sortParams.order && props.sortParams.order === "desc" ? "descending" : "ascending"
      };
    });
    const isMobile = computed(() => store.getters.getIsMobile);
    return {
      currentSort,
      localRows,
      settings,
      showPagination,
      isMobile,
      onMouseEnter,
      onMouseLeave,
      onRowClick,
      onSortChanged,
      onPageChanged,
      onPageSizeChanged,
      rowClassSelector,
      stringFormat
    };
  }
});