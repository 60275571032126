import { defineComponent, onMounted, ref, watch, watchEffect } from "vue";
import MVT from "ol/format/MVT";
import { VectorTile as VectorTileLayer } from "ol/layer";
import { VectorTile as VectorTileSource } from "ol/source";
import { apiModule } from "@/store/api";
import EvgBasemap from "@/lib/vue3-openlayers-ext/components/layers/Basemap.vue";
import { defaultPolygonStyles } from "@/lib/polygonStyles";
import { findTopCandidate, WGS2WM } from "@/lib/geofun";
export default defineComponent({
  name: "ProductMap",
  components: {
    EvgBasemap
  },
  props: {
    publicationGid: {
      type: String,
      required: false
    },
    productGid: {
      type: String,
      required: false
    },
    bounds: {
      type: Array,
      required: false
    }
  },
  emits: ["feature:clicked"],
  setup(props, {
    emit
  }) {
    const projection = ref("EPSG:3857");
    const view = ref();
    const mapWrapper = ref();
    const vtLayer = ref();
    const activeVtLayer = ref();
    const vtSource = new VectorTileSource({
      attributions: "East View Geospatial",
      format: new MVT()
    });
    const fitToBounds = () => {
      if (props.bounds) {
        const b = props.bounds;
        if (b.length === 4) {
          view.value?.fit([...WGS2WM(b[0], b[1]), ...WGS2WM(b[2], b[3])], {
            padding: [32, 32, 32, 32],
            duration: 250
          });
        }
      }
    };
    const updateVtSource = () => {
      if (props.publicationGid) {
        vtSource.setUrl(`${apiModule.baseUrl}/api/Publication/${props.publicationGid}/tile/{z}/{x}/{y}.mvt`);
        vtLayer.value?.setSource(vtSource);
        activeVtLayer.value?.setSource(vtSource);
      }
    };
    watchEffect(fitToBounds);
    watchEffect(updateVtSource);
    watch(() => props.productGid, () => activeVtLayer.value?.changed());
    onMounted(() => {
      const map = mapWrapper.value?.map;
      if (map) {
        vtLayer.value = new VectorTileLayer({
          className: "products"
        });
        vtLayer.value.setStyle(f => {
          if (f.getProperties()["sales"] > 0) {
            return defaultPolygonStyles.standard;
          }
          return defaultPolygonStyles.unavailable;
        });
        activeVtLayer.value = new VectorTileLayer({
          className: "activeProduct"
        });
        activeVtLayer.value.setStyle(f => {
          if (f.getProperties()["gid"] === props.productGid) {
            return defaultPolygonStyles.active;
          }
          return undefined;
        });
        map.on("click", e => {
          const f = findTopCandidate(e);
          if (f) {
            emit("feature:clicked", f.getProperties());
          }
        });
        map.addLayer(vtLayer.value);
        map.addLayer(activeVtLayer.value);
      }
    });
    return {
      mapWrapper,
      projection,
      view
    };
  }
});