import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ol_view = _resolveComponent("ol-view");
  const _component_evg_basemap = _resolveComponent("evg-basemap");
  const _component_ol_map = _resolveComponent("ol-map");
  return _openBlock(), _createBlock(_component_ol_map, {
    class: "map-container",
    ref: "mapWrapper"
  }, {
    default: _withCtx(() => [_createVNode(_component_ol_view, {
      ref: "view",
      projection: _ctx.projection
    }, null, 8, ["projection"]), _createVNode(_component_evg_basemap)]),
    _: 1
  }, 512);
}