import { computed, defineComponent } from "vue";
import { snipcartModule } from "@/store/snipcart";
import AccountCheckOutlineIcon from "vue-material-design-icons/AccountCheckOutline.vue";
import AccountOutlineIcon from "vue-material-design-icons/AccountOutline.vue";
export default defineComponent({
  components: {
    AccountCheckOutlineIcon,
    AccountOutlineIcon
  },
  setup() {
    const isSignedIn = computed(() => {
      if (snipcartModule.customer) {
        return snipcartModule.customer.status === "SignedIn";
      }
      return false;
    });
    return {
      isSignedIn
    };
  }
});