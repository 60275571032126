import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { countries } from "../lib/filters/Countries";
import { ElNotification as Notification } from "element-plus";
import { snipcartModule } from "@/store/snipcart";
import { apiModule } from "@/store/api";
import Bugsnag from "@bugsnag/js";
import store from "@/store";
export default defineComponent({
  emits: ["close"],
  setup() {
    const ruleFormRef = ref();
    const isEnabled = ref(true);
    const isVisible = computed({
      get: () => store.getters.getIsRfpModalOpen,
      set: value => {
        store.commit("setIsRfpModalOpen", value);
      }
    });
    const rfpProducts = computed(() => snipcartModule.rfpItems);
    const rfpSkus = computed(() => rfpProducts.value.map(item => item.id));
    const form = reactive({
      firstName: "",
      lastName: "",
      email: "",
      country: "",
      company: "",
      title: "",
      phoneNumber: "",
      inquiry: ""
    });
    const rules = reactive({
      firstName: [{
        required: true,
        message: "first name is required",
        trigger: "blur"
      }],
      lastName: [{
        required: true,
        message: "last name is required",
        trigger: "blur"
      }],
      email: [{
        required: true,
        message: "email is required",
        trigger: "blur"
      }, {
        type: "email",
        message: "email is not valid",
        trigger: "blur"
      }],
      country: [{
        required: true,
        message: "Please select your country",
        trigger: "change"
      }]
    });
    const countryList = ref(countries.sort((a, b) => a.name.localeCompare(b.name)));
    const ensureCsrfToken = () => {
      if (!apiModule.csrfToken) {
        apiModule.fetchCsrfToken().catch(() => {
          Bugsnag.notify(new Error("Failed to retrieve CSRF token."));
        });
      }
    };
    onMounted(() => {
      Bugsnag.leaveBreadcrumb("RFP dialog mounted");
      ensureCsrfToken();
      if (apiModule.csrfToken !== undefined) {
        isEnabled.value = true;
      }
    });
    const submitRFP = formEl => {
      if (!formEl) return;
      formEl.validate(valid => {
        if (!valid) return;
        apiModule.rfpClient.post(form.lastName, form.firstName, form.country, form.title, form.company, null, form.email, form.phoneNumber, null, form.inquiry, rfpSkus.value, null, null).then(() => {
          snipcartModule.resetItems()
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch(err => {
            Bugsnag.notify(new Error("Failed to reset cart"), event => {
              event.addMetadata("error", err);
            });
          });
        }).then(() => isVisible.value = false)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch(err => {
          Bugsnag.notify(new Error("RFP request failed"), event => {
            event.addMetadata("error", err);
            event.addMetadata("form", form);
            event.addMetadata("skus", rfpSkus.value);
          });
          Notification({
            title: "Requests not sent",
            message: "Please try again. If the issue persists, please contact us via email: evg_web_support@eastview.com",
            position: "bottom-right",
            type: "error",
            duration: 10000
          });
          apiModule.clearCsrfToken();
        });
      });
    };
    return {
      form,
      countryList,
      isEnabled,
      isVisible,
      submitRFP,
      ruleFormRef,
      rules
    };
  }
});