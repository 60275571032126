import { computed, defineComponent, onMounted, onUpdated, reactive } from "vue";
import VariantCheckboxGroup from "./VariantCheckboxGroup.vue";
import { allVariants, dateValues, noneVariant, scaleValues } from "@/lib/evg-shop-ext";
import { productTypes } from "@/lib/filters/Types";
import EvRangeSlider from "./EvRangeSlider.vue";
import { Filters, searchModule } from "@/store/search";
import isEqual from "lodash/isEqual";
export default defineComponent({
  name: "filterbox",
  components: {
    VariantCheckboxGroup,
    EvRangeSlider
  },
  props: {
    modelValue: {
      required: true,
      type: Object
    },
    blankFilters: {
      required: false,
      type: Object
    },
    title: {
      type: String,
      required: false
    },
    allowTypeSelection: {
      type: Boolean,
      default: false
    },
    allowCountrySelection: {
      type: Boolean,
      default: false
    },
    allowPublisherSelection: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue", "clear"],
  setup(props, {
    emit
  }) {
    const filters = reactive(new Filters(props.modelValue));
    const applyFilter = () => {
      emit("update:modelValue", filters);
    };
    const resetFilter = () => {
      emit("clear");
    };
    const applyContraints = () => {
      filters.init(props.modelValue);
    };
    const changed = computed(() => !isEqual(new Filters(filters), new Filters(props.modelValue)));
    const asNew = computed(() => !(props.blankFilters && isEqual(new Filters(filters), props.blankFilters)));
    onMounted(applyContraints);
    onUpdated(applyContraints);
    return {
      allVariants,
      noneVariant,
      changed,
      asNew,
      filters,
      countries: computed(() => searchModule.countries),
      languages: computed(() => searchModule.languages),
      publishers: computed(() => searchModule.publishers),
      dateValues,
      scaleValues,
      productTypes,
      applyFilter,
      resetFilter
    };
  }
});