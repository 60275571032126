import FilterBox from "@/components/FilterBox.vue";
import { MatchCounts } from "@/lib/evg-shop";
import { getPublicationSlug } from "@/lib/evg-shop-ext";
import { computed, defineComponent, onMounted, onUpdated, reactive, ref, watch } from "vue";
import { Filters, searchModule } from "@/store/search";
import { browseModule } from "@/store/browse";
import PublicationTable from "@/components/PublicationTable.vue";
export default defineComponent({
  name: "PublicationByType",
  props: {
    primaryType: {
      type: String,
      required: true
    }
  },
  components: {
    FilterBox,
    PublicationTable
  },
  methods: {
    getPublicationSlug: p => getPublicationSlug(p)
  },
  setup(props) {
    const columns = ["aggregate.name", "aggregate.publisherName", "aggregate.scaleName", "matches"].map(p => ({
      prop: p
    }));
    const matchCounts = reactive(new MatchCounts());
    const filters = computed(() => searchModule.filters);
    const editFilters = ref(new Filters(filters.value));
    const blankFilters = ref(new Filters());
    const hasCounts = computed(() => {
      return matchCounts.publications > 0 || matchCounts.products > 0;
    });
    const updateCounts = () => {
      searchModule.enforceType(props.primaryType);
      searchModule.fetchMatchCounts(filters.value).then(c => {
        matchCounts.init(c);
      });
    };
    const updateFilters = newFilters => {
      searchModule.updateFilters(newFilters);
      updateCounts();
    };
    const resetFilter = () => {
      searchModule.resetFilters();
      searchModule.enforceType(props.primaryType);
      editFilters.value = new Filters(filters.value);
      updateCounts();
    };
    const onEnter = () => {
      if (browseModule.activeType !== props.primaryType) {
        browseModule.updateActiveType(props.primaryType);
      }
      document.title = `Global Explorer - search: ${props.primaryType}`;
      blankFilters.value.enforceType(props.primaryType);
      updateCounts();
    };
    watch(() => props.primaryType, onEnter);
    watch(() => searchModule.filters, () => editFilters.value.init(searchModule.filters), {
      deep: true
    });
    onMounted(onEnter);
    onUpdated(onEnter);
    return {
      blankFilters,
      columns,
      filters,
      editFilters,
      hasCounts,
      matchCounts,
      updateFilters,
      resetFilter
    };
  }
});