import { Fill, Stroke, Style } from "ol/style";
import Color from "color";

interface IFlatStyle {
  color?: string;
  weight?: number;
  opacity?: number;
  fill?: boolean;
  fillColor?: string;
  fillOpacity?: number;
  zIndex?: number;
  userDrawn?: boolean;
}

const flatToOl = (flat: IFlatStyle): Style => {
  return new Style({
    stroke: new Stroke({
      color: flat.color,
      width: flat.weight,
    }),
    fill: flat.fill
      ? new Fill({
          color: Color(flat.fillColor)
            .alpha(flat.fillOpacity ? flat.fillOpacity : 1)
            .toString(),
        })
      : undefined,
    zIndex: flat.zIndex,
  });
};

const buildDefaultPolygonStyles = (
  border?: string,
  fill?: string,
  unAvailable?: string
) => {
  const standardBorderColor = border || "hsl(75, 28%, 38%)";
  const standardFillColor = fill || "hsl(111, 100%, 50%)";
  const unAvailableColor = unAvailable || "hsl(51, 100%, 50%)";

  const flatPolygonStyle: { [k: string]: IFlatStyle } = {
    standard: {
      color: standardBorderColor,
      weight: 1,
      fill: true,
      fillColor: standardFillColor,
      fillOpacity: 0.01,
    },
    debug: {
      color: "#FF00FF",
      weight: 1,
      fill: true,
      fillColor: "#F00",
      fillOpacity: 0.3,
    },
    unavailable: {
      color: `hsl(51, 28%, 38%)`,
      weight: 1,
      fill: true,
      fillColor: unAvailableColor,
      fillOpacity: 0.01,
    },
    highlighted: {
      color: "#ff0000",
      weight: 2,
      zIndex: 700, //Note: at the moment, there is no way to change the drawing order
      fillOpacity: 0.8,
    },
    active: {
      weight: 4,
      color: standardBorderColor,
    },
    userDrawn: {
      color: "#ad525a",
      opacity: 1,
      fill: false,
      userDrawn: true,
    },
  };
  const styles: { [k: string]: Style } = {};
  Object.entries(flatPolygonStyle).forEach(([k, v]) => {
    styles[k] = flatToOl(v);
  });
  return styles;
};

export const defaultPolygonStyles = buildDefaultPolygonStyles(
  process.env.POLYGON_BORDER_COLOR_STD,
  process.env.POLYGON_FILL_COLOR_STD,
  process.env.POLYGON_FILL_COLOR_RFPONLY
);
