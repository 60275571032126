import { MOBILE_MAX_WIDTH } from "@/constants";
import { Store } from "vuex";

export interface IAppState {
  baseUrl: string;
  snipcartUrl: string;
  mapTilerKey: string;
  layout: "catalog" | "explorer";
  isMobile: boolean;
  isRfpModalOpen: boolean;
}

const initializeState = (): IAppState => {
  const envData = document.querySelector("#evg-env") as HTMLElement;

  const config: IAppState = {
    baseUrl: envData?.dataset["apiUrl"] || process.env.VUE_APP_SHOP_API_URL,
    snipcartUrl:
      envData?.dataset["snipcartUrl"] ||
      process.env.VUE_APP_SNIPCART_CALLBACK_DOMAIN,
    mapTilerKey: envData?.dataset["mtKey"] || process.env.VUE_APP_MAPTILER_KEY,
    layout: "catalog",
    isMobile: window?.innerWidth ? window.innerWidth < MOBILE_MAX_WIDTH : false,
    isRfpModalOpen: false,
  };
  envData.remove();
  return config;
};

export default new Store<IAppState>({
  state: initializeState(),
  getters: {
    getLayout: (state) => state.layout,
    hasMapLayout: (state) => state.layout === "explorer",
    getIsMobile: (state) => state.isMobile,
    getIsRfpModalOpen: (state) => state.isRfpModalOpen,
  },
  mutations: {
    setLayout(state, lyt: "catalog" | "explorer") {
      state.layout = lyt;
    },
    setIsMobile(state, isMobile: boolean) {
      state.isMobile = isMobile;
    },
    setIsRfpModalOpen(state, isOpen: boolean) {
      state.isRfpModalOpen = isOpen;
    },
  },
});
