import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_map_outline_icon = _resolveComponent("map-outline-icon");
  const _component_variant_checkbox = _resolveComponent("variant-checkbox");
  const _component_file_image_icon = _resolveComponent("file-image-icon");
  const _component_database_marker_outline_icon = _resolveComponent("database-marker-outline-icon");
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
  return _openBlock(), _createBlock(_component_el_checkbox_group, {
    class: "icons",
    modelValue: _ctx.selectedVariants,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.selectedVariants = $event),
    size: _ctx.$props.size ?? 'medium'
  }, {
    default: _withCtx(() => [_createVNode(_component_variant_checkbox, {
      label: "Paper",
      isActive: _ctx.$props.active.print,
      isPrimary: _ctx.$props.primary.print,
      emphasize: _ctx.$props.emphasize?.print,
      "onMouse:over": _ctx.onMouseOver,
      "onMouse:leave": _ctx.onMouseLeave
    }, {
      default: _withCtx(() => [_createVNode(_component_map_outline_icon)]),
      _: 1
    }, 8, ["isActive", "isPrimary", "emphasize", "onMouse:over", "onMouse:leave"]), _createVNode(_component_variant_checkbox, {
      label: "Image",
      isActive: _ctx.$props.active.image,
      isPrimary: _ctx.$props.primary.image,
      emphasize: _ctx.$props.emphasize?.image,
      "onMouse:over": _ctx.onMouseOver,
      "onMouse:leave": _ctx.onMouseLeave
    }, {
      default: _withCtx(() => [_createVNode(_component_file_image_icon)]),
      _: 1
    }, 8, ["isActive", "isPrimary", "emphasize", "onMouse:over", "onMouse:leave"]), _createVNode(_component_variant_checkbox, {
      label: "Data",
      isActive: _ctx.$props.active.data,
      isPrimary: _ctx.$props.primary.data,
      emphasize: _ctx.$props.emphasize?.data,
      "onMouse:over": _ctx.onMouseOver,
      "onMouse:leave": _ctx.onMouseLeave
    }, {
      default: _withCtx(() => [_createVNode(_component_database_marker_outline_icon)]),
      _: 1
    }, 8, ["isActive", "isPrimary", "emphasize", "onMouse:over", "onMouse:leave"])]),
    _: 1
  }, 8, ["modelValue", "size"]);
}