import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_drawer = _resolveComponent("el-drawer");
  const _component_el_container = _resolveComponent("el-container");
  return _openBlock(), _createBlock(_component_el_container, {
    class: "app-drawer"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_drawer, {
      modelValue: _ctx.showDrawer,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.showDrawer = $event),
      title: _ctx.heading,
      direction: "rtl",
      "custom-class": "app-drawer-content",
      "before-close": _ctx.handleClose
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
      _: 3
    }, 8, ["modelValue", "title", "before-close"])]),
    _: 3
  });
}