import uniq from "lodash/uniq";
import flatten from "lodash/flatten";

export class Country {
  public code!: string;
  public name!: string;
  public regions!: string[];
}

// TODO: fetch from a db collection instead to allow easy adjustement by admins without redeployment
export const countries: Country[] = [
  { code: "ANG", name: "Angola", regions: ["Africa"] },
  { code: "BIF", name: "Bassas Da India", regions: ["Africa"] },
  { code: "BEN", name: "Benin", regions: ["Africa"] },
  { code: "BOT", name: "Botswana", regions: ["Africa"] },
  { code: "BUR", name: "Burkina Faso", regions: ["Africa"] },
  { code: "BDI", name: "Burundi", regions: ["Africa"] },
  { code: "CIV", name: "Côte d'Ivoire", regions: ["Africa"] },
  { code: "CMR", name: "Cameroon", regions: ["Africa"] },
  { code: "CPV", name: "Cape Verde", regions: ["Africa"] },
  { code: "CAF", name: "Central African Republic", regions: ["Africa"] },
  { code: "CHA", name: "Chad", regions: ["Africa"] },
  { code: "COM", name: "Comoros", regions: ["Africa"] },
  { code: "CGO", name: "Congo (Brazzaville)", regions: ["Africa"] },
  { code: "COD", name: "Congo (Kinshasa)", regions: ["Africa"] },
  { code: "DJI", name: "Djibouti", regions: ["Africa"] },
  { code: "EQG", name: "Equatorial Guinea", regions: ["Africa"] },
  { code: "ERI", name: "Eritrea", regions: ["Africa"] },
  { code: "ETH", name: "Ethiopia", regions: ["Africa"] },
  { code: "EIF", name: "Europa Island", regions: ["Africa"] },
  { code: "GAB", name: "Gabon", regions: ["Africa"] },
  { code: "GAM", name: "Gambia, The", regions: ["Africa"] },
  { code: "GHA", name: "Ghana", regions: ["Africa"] },
  { code: "GUI", name: "Guinea", regions: ["Africa"] },
  { code: "GBS", name: "Guinea-Bissau", regions: ["Africa"] },
  { code: "JNI", name: "Juan De Nova Island", regions: ["Africa"] },
  { code: "KEN", name: "Kenya", regions: ["Africa"] },
  { code: "LES", name: "Lesotho", regions: ["Africa"] },
  { code: "LBR", name: "Liberia", regions: ["Africa"] },
  { code: "MAD", name: "Madagascar", regions: ["Africa"] },
  { code: "MAW", name: "Malawi", regions: ["Africa"] },
  { code: "MLI", name: "Mali", regions: ["Africa"] },
  { code: "MTN", name: "Mauritania", regions: ["Africa"] },
  { code: "MRI", name: "Mauritius", regions: ["Africa"] },
  { code: "MYT", name: "Mayotte", regions: ["Africa"] },
  { code: "MOZ", name: "Mozambique", regions: ["Africa"] },
  { code: "NAM", name: "Namibia", regions: ["Africa"] },
  { code: "NIG", name: "Niger", regions: ["Africa"] },
  { code: "NGR", name: "Nigeria", regions: ["Africa"] },
  { code: "REU", name: "Reunion", regions: ["Africa"] },
  { code: "RWA", name: "Rwanda", regions: ["Africa"] },
  { code: "STH", name: "Saint Helena, Ascension, and Tristan da Cunha", regions: ["Africa"] },
  { code: "STP", name: "Sao Tome And Principe", regions: ["Africa"] },
  { code: "SEN", name: "Senegal", regions: ["Africa"] },
  { code: "SEY", name: "Seychelles", regions: ["Africa"] },
  { code: "SLE", name: "Sierra Leone", regions: ["Africa"] },
  { code: "SOM", name: "Somalia", regions: ["Africa"] },
  { code: "SAF", name: "South Africa", regions: ["Africa"] },
  { code: "SSD", name: "South Sudan", regions: ["Africa"] },
  { code: "SUD", name: "Sudan", regions: ["Africa"] },
  { code: "SWZ", name: "Eswatini", regions: ["Africa"] },
  { code: "TAN", name: "Tanzania", regions: ["Africa"] },
  { code: "TOG", name: "Togo", regions: ["Africa"] },
  { code: "TIF", name: "Tromelin Island", regions: ["Africa"] },
  { code: "TUN", name: "Tunisia", regions: ["Africa"] },
  { code: "UGA", name: "Uganda", regions: ["Africa"] },
  { code: "WSA", name: "Western Sahara", regions: ["Africa"] },
  { code: "ZAM", name: "Zambia", regions: ["Africa"] },
  { code: "ZIM", name: "Zimbabwe", regions: ["Africa"] },
  { code: "ALG", name: "Algeria", regions: ["Africa", "Middle East"] },
  { code: "EGY", name: "Egypt", regions: ["Africa", "Middle East"] },
  { code: "LBY", name: "Libya", regions: ["Africa", "Middle East"] },
  { code: "MOR", name: "Morocco", regions: ["Africa", "Middle East"] },
  { code: "ATA", name: "Antarctica", regions: ["Antarctica"] },
  { code: "BVT", name: "Bouvet Island", regions: ["Antarctica"] },
  { code: "FSA", name: "French Southern Territories", regions: ["Antarctica"] },
  { code: "BAN", name: "Bangladesh", regions: ["Asia"] },
  { code: "BHU", name: "Bhutan", regions: ["Asia"] },
  { code: "IOT", name: "British Indian Ocean Territory", regions: ["Asia"] },
  { code: "BRU", name: "Brunei Darussalam", regions: ["Asia"] },
  { code: "CAM", name: "Cambodia", regions: ["Asia"] },
  { code: "CHN", name: "China", regions: ["Asia"] },
  { code: "HKG", name: "Hong Kong", regions: ["Asia"] },
  { code: "IND", name: "India", regions: ["Asia"] },
  { code: "INA", name: "Indonesia", regions: ["Asia"] },
  { code: "JPN", name: "Japan", regions: ["Asia"] },
  { code: "PRK", name: "Korea, North", regions: ["Asia"] },
  { code: "KOR", name: "Korea, South", regions: ["Asia"] },
  { code: "LAO", name: "Laos", regions: ["Asia"] },
  { code: "MAC", name: "Macao", regions: ["Asia"] },
  { code: "MAS", name: "Malaysia", regions: ["Asia"] },
  { code: "MDV", name: "Maldives", regions: ["Asia"] },
  { code: "MGL", name: "Mongolia", regions: ["Asia"] },
  { code: "MYA", name: "Myanmar (Burma)", regions: ["Asia"] },
  { code: "NEP", name: "Nepal", regions: ["Asia"] },
  { code: "YYY", name: "Other", regions: ["Asia"] },
  { code: "PNG", name: "Papua New Guinea", regions: ["Asia"] },
  { code: "PCI", name: "Paracel Islands", regions: ["Asia"] },
  { code: "PHI", name: "Philippines", regions: ["Asia"] },
  { code: "SIN", name: "Singapore", regions: ["Asia"] },
  { code: "SLI", name: "Spratly Islands", regions: ["Asia"] },
  { code: "SRI", name: "Sri Lanka", regions: ["Asia"] },
  { code: "TWN", name: "Taiwan", regions: ["Asia"] },
  { code: "THA", name: "Thailand", regions: ["Asia"] },
  { code: "TIM", name: "East Timor", regions: ["Asia"] },
  { code: "VIE", name: "Vietnam", regions: ["Asia"] },
  { code: "KAZ", name: "Kazakhstan", regions: ["Asia", "Former USSR"] },
  { code: "KGZ", name: "Kyrgyzstan", regions: ["Asia", "Former USSR"] },
  { code: "RUS", name: "Russia", regions: ["Asia", "Former USSR"] },
  { code: "TJK", name: "Tajikistan", regions: ["Asia", "Former USSR"] },
  { code: "TKM", name: "Turkmenistan", regions: ["Asia", "Former USSR"] },
  { code: "UZB", name: "Uzbekistan", regions: ["Asia", "Former USSR"] },
  { code: "AFG", name: "Afghanistan", regions: ["Asia", "Middle East"] },
  { code: "PAK", name: "Pakistan", regions: ["Asia", "Middle East"] },
  { code: "ASA", name: "American Samoa", regions: ["Australia And Oceania"] },
  { code: "ASH", name: "Ashmore And Cartier Islands", regions: ["Australia And Oceania"] },
  { code: "AUS", name: "Australia", regions: ["Australia And Oceania"] },
  { code: "BKI", name: "Baker Island", regions: ["Australia And Oceania"] },
  { code: "CXR", name: "Christmas Island", regions: ["Australia And Oceania"] },
  { code: "CIF", name: "Clipperton Island", regions: ["Australia And Oceania"] },
  { code: "CCK", name: "Cocos (Keeling) Islands", regions: ["Australia And Oceania"] },
  { code: "COK", name: "Cook Islands", regions: ["Australia And Oceania"] },
  { code: "CSI", name: "Coral Sea Islands", regions: ["Australia And Oceania"] },
  { code: "FIJ", name: "Fiji", regions: ["Australia And Oceania"] },
  { code: "FPO", name: "French Polynesia", regions: ["Australia And Oceania"] },
  { code: "GIF", name: "Glorioso Islands", regions: ["Australia And Oceania"] },
  { code: "GUM", name: "Guam", regions: ["Australia And Oceania"] },
  { code: "HMD", name: "Heard Island And Mcdonald Islands", regions: ["Australia And Oceania"] },
  { code: "HLI", name: "Howland Island", regions: ["Australia And Oceania"] },
  { code: "JVI", name: "Jarvis Island", regions: ["Australia And Oceania"] },
  { code: "JSA", name: "Johnston Atoll", regions: ["Australia And Oceania"] },
  { code: "KMR", name: "Kingman Reef", regions: ["Australia And Oceania"] },
  { code: "KIR", name: "Kiribati", regions: ["Australia And Oceania"] },
  { code: "MHL", name: "Marshall Islands", regions: ["Australia And Oceania"] },
  { code: "MIC", name: "Micronesia, Federated States Of", regions: ["Australia And Oceania"] },
  { code: "MWI", name: "Midway Islands", regions: ["Australia And Oceania"] },
  { code: "NRU", name: "Nauru", regions: ["Australia And Oceania"] },
  { code: "NCL", name: "New Caledonia", regions: ["Australia And Oceania"] },
  { code: "NZL", name: "New Zealand", regions: ["Australia And Oceania"] },
  { code: "NIU", name: "Niue", regions: ["Australia And Oceania"] },
  { code: "NFK", name: "Norfolk Island", regions: ["Australia And Oceania"] },
  { code: "NMI", name: "Northern Mariana Islands", regions: ["Australia And Oceania"] },
  { code: "PLU", name: "Palau", regions: ["Australia And Oceania"] },
  { code: "PMA", name: "Palmyra Atoll", regions: ["Australia And Oceania"] },
  { code: "PCN", name: "Pitcairn Islands", regions: ["Australia And Oceania"] },
  { code: "SAM", name: "Samoa", regions: ["Australia And Oceania"] },
  { code: "SOL", name: "Solomon Islands", regions: ["Australia And Oceania"] },
  { code: "TKL", name: "Tokelau", regions: ["Australia And Oceania"] },
  { code: "TGA", name: "Tonga", regions: ["Australia And Oceania"] },
  { code: "TUV", name: "Tuvalu", regions: ["Australia And Oceania"] },
  { code: "VAN", name: "Vanuatu", regions: ["Australia And Oceania"] },
  { code: "WKA", name: "Wake Island", regions: ["Australia And Oceania"] },
  { code: "WLF", name: "Wallis And Futuna", regions: ["Australia And Oceania"] },
  { code: "ANT", name: "Antigua And Barbuda", regions: ["Central America and the Caribbean"] },
  { code: "ARU", name: "Aruba", regions: ["Central America and the Caribbean"] },
  { code: "BAH", name: "The Bahamas", regions: ["Central America and the Caribbean"] },
  { code: "BAR", name: "Barbados", regions: ["Central America and the Caribbean"] },
  { code: "BIZ", name: "Belize", regions: ["Central America and the Caribbean"] },
  { code: "BER", name: "Bermuda", regions: ["Central America and the Caribbean"] },
  { code: "CAY", name: "Cayman Islands", regions: ["Central America and the Caribbean"] },
  { code: "CRC", name: "Costa Rica", regions: ["Central America and the Caribbean"] },
  { code: "DMA", name: "Dominica", regions: ["Central America and the Caribbean"] },
  { code: "DOM", name: "Dominican Republic", regions: ["Central America and the Caribbean"] },
  { code: "ESA", name: "El Salvador", regions: ["Central America and the Caribbean"] },
  { code: "GRD", name: "Grenada", regions: ["Central America and the Caribbean"] },
  { code: "GLP", name: "Guadeloupe", regions: ["Central America and the Caribbean"] },
  { code: "GUA", name: "Guatemala", regions: ["Central America and the Caribbean"] },
  { code: "HAI", name: "Haiti", regions: ["Central America and the Caribbean"] },
  { code: "HON", name: "Honduras", regions: ["Central America and the Caribbean"] },
  { code: "JAM", name: "Jamaica", regions: ["Central America and the Caribbean"] },
  { code: "MTQ", name: "Martinique", regions: ["Central America and the Caribbean"] },
  { code: "MSR", name: "Montserrat", regions: ["Central America and the Caribbean"] },
  { code: "NVI", name: "Navassa Island", regions: ["Central America and the Caribbean"] },
  { code: "NAT", name: "Netherlands Antilles", regions: ["Central America and the Caribbean"] },
  { code: "CUR", name: "Curacao", regions: ["Central America and the Caribbean", "South America"] },
  { code: "SIM", name: "Sint Maarten", regions: ["Central America and the Caribbean"] },
  { code: "NIC", name: "Nicaragua", regions: ["Central America and the Caribbean"] },
  { code: "PAN", name: "Panama", regions: ["Central America and the Caribbean"] },
  { code: "PRI", name: "Puerto Rico", regions: ["Central America and the Caribbean"] },
  { code: "STB", name: "Saint Barthelemy", regions: ["Central America and the Caribbean"] },
  { code: "SKN", name: "Saint Kitts And Nevis", regions: ["Central America and the Caribbean"] },
  { code: "STL", name: "Saint Lucia", regions: ["Central America and the Caribbean"] },
  { code: "STM", name: "Saint Martin", regions: ["Central America and the Caribbean"] },
  { code: "SVG", name: "Saint Vincent And The Grenadines", regions: ["Central America and the Caribbean"] },
  { code: "TRI", name: "Trinidad And Tobago", regions: ["Central America and the Caribbean"] },
  { code: "VIB", name: "Virgin Islands, British", regions: ["Central America and the Caribbean"] },
  { code: "VIU", name: "Virgin Islands, United States", regions: ["Central America and the Caribbean"] },
  { code: "AIA", name: "Anguilla", regions: ["Central America and the Caribbean"] },
  { code: "CUB", name: "Cuba", regions: ["Central America and the Caribbean"] },
  { code: "TCA", name: "Turks And Caicos Islands", regions: ["Central America and the Caribbean"] },
  { code: "ALB", name: "Albania", regions: ["Europe"] },
  { code: "ANR", name: "Andorra", regions: ["Europe"] },
  { code: "AUT", name: "Austria", regions: ["Europe"] },
  { code: "BEL", name: "Belgium", regions: ["Europe"] },
  { code: "BIH", name: "Bosnia And Herzegovina", regions: ["Europe"] },
  { code: "BUL", name: "Bulgaria", regions: ["Europe"] },
  { code: "CRO", name: "Croatia", regions: ["Europe"] },
  { code: "CYP", name: "Cyprus", regions: ["Europe"] },
  { code: "CZE", name: "Czechia", regions: ["Europe"] },
  { code: "DEN", name: "Denmark", regions: ["Europe"] },
  { code: "FRO", name: "Faroe Islands", regions: ["Europe"] },
  { code: "FIN", name: "Finland", regions: ["Europe"] },
  { code: "FRA", name: "France", regions: ["Europe"] },
  { code: "GER", name: "Germany", regions: ["Europe"] },
  { code: "GIB", name: "Gibraltar", regions: ["Europe"] },
  { code: "GRE", name: "Greece", regions: ["Europe"] },
  { code: "GGY", name: "Guernsey", regions: ["Europe"] },
  { code: "HUN", name: "Hungary", regions: ["Europe"] },
  { code: "ICE", name: "Iceland", regions: ["Europe"] },
  { code: "IRL", name: "Ireland", regions: ["Europe"] },
  { code: "IMN", name: "Isle Of Man", regions: ["Europe"] },
  { code: "ITA", name: "Italy", regions: ["Europe"] },
  { code: "JMN", name: "Jan Mayen", regions: ["Europe"] },
  { code: "JEY", name: "Jersey", regions: ["Europe"] },
  { code: "KOS", name: "Kosovo", regions: ["Europe"] },
  { code: "LIE", name: "Liechtenstein", regions: ["Europe"] },
  { code: "LUX", name: "Luxembourg", regions: ["Europe"] },
  { code: "MKD", name: "North Macedonia", regions: ["Europe"] },
  { code: "MLT", name: "Malta", regions: ["Europe"] },
  { code: "MON", name: "Monaco", regions: ["Europe"] },
  { code: "MNE", name: "Montenegro", regions: ["Europe"] },
  { code: "NED", name: "Netherlands", regions: ["Europe"] },
  { code: "NOR", name: "Norway", regions: ["Europe"] },
  { code: "POL", name: "Poland", regions: ["Europe"] },
  { code: "POR", name: "Portugal", regions: ["Europe"] },
  { code: "ROM", name: "Romania", regions: ["Europe"] },
  { code: "SMR", name: "San Marino", regions: ["Europe"] },
  { code: "SRB", name: "Serbia", regions: ["Europe"] },
  { code: "SVK", name: "Slovakia", regions: ["Europe"] },
  { code: "SLO", name: "Slovenia", regions: ["Europe"] },
  { code: "ESP", name: "Spain", regions: ["Europe"] },
  { code: "SBN", name: "Svalbard", regions: ["Europe"] },
  { code: "SWE", name: "Sweden", regions: ["Europe"] },
  { code: "SUI", name: "Switzerland", regions: ["Europe"] },
  { code: "GBR", name: "United Kingdom", regions: ["Europe"] },
  { code: "VAT", name: "Vatican City", regions: ["Europe"] },
  { code: "YUG", name: "Yugoslavia, Former", regions: ["Europe"] },
  { code: "ARM", name: "Armenia", regions: ["Europe", "Former USSR"] },
  { code: "BLR", name: "Belarus", regions: ["Europe", "Former USSR"] },
  { code: "EST", name: "Estonia", regions: ["Europe", "Former USSR"] },
  { code: "GEO", name: "Georgia", regions: ["Europe", "Former USSR"] },
  { code: "LAT", name: "Latvia", regions: ["Europe", "Former USSR"] },
  { code: "LTU", name: "Lithuania", regions: ["Europe", "Former USSR"] },
  { code: "MDA", name: "Moldova", regions: ["Europe", "Former USSR"] },
  { code: "UKR", name: "Ukraine", regions: ["Europe", "Former USSR"] },
  { code: "BRN", name: "Bahrain", regions: ["Middle East"] },
  { code: "GAZ", name: "Gaza Strip", regions: ["Middle East"] },
  { code: "IRN", name: "Iran", regions: ["Middle East"] },
  { code: "IRQ", name: "Iraq", regions: ["Middle East"] },
  { code: "ISR", name: "Israel", regions: ["Middle East"] },
  { code: "JOR", name: "Jordan", regions: ["Middle East"] },
  { code: "KWT", name: "Kuwait", regions: ["Middle East"] },
  { code: "LBN", name: "Lebanon", regions: ["Middle East"] },
  { code: "OMA", name: "Oman", regions: ["Middle East"] },
  { code: "QAT", name: "Qatar", regions: ["Middle East"] },
  { code: "SAU", name: "Saudi Arabia", regions: ["Middle East"] },
  { code: "SYR", name: "Syria", regions: ["Middle East"] },
  { code: "TUR", name: "Turkey", regions: ["Middle East"] },
  { code: "UAE", name: "United Arab Emirates", regions: ["Middle East"] },
  { code: "WBA", name: "West Bank", regions: ["Middle East"] },
  { code: "YEM", name: "Yemen", regions: ["Middle East"] },
  { code: "AZE", name: "Azerbaijan", regions: ["Middle East", "Former USSR"] },
  { code: "CAN", name: "Canada", regions: ["North America"] },
  { code: "GRL", name: "Greenland", regions: ["North America"] },
  { code: "MEX", name: "Mexico", regions: ["North America"] },
  { code: "SPM", name: "Saint Pierre And Miquelon", regions: ["North America"] },
  { code: "USA", name: "United States", regions: ["North America"] },
  { code: "VEN", name: "Venezuela", regions: ["South America"] },
  { code: "ARG", name: "Argentina", regions: ["South America"] },
  { code: "BOL", name: "Bolivia", regions: ["South America"] },
  { code: "BRA", name: "Brazil", regions: ["South America"] },
  { code: "CHI", name: "Chile", regions: ["South America"] },
  { code: "COL", name: "Colombia", regions: ["South America"] },
  { code: "ECU", name: "Ecuador", regions: ["South America"] },
  { code: "FLK", name: "Falkland Islands (Islas Malvinas)", regions: ["South America"] },
  { code: "FGU", name: "French Guiana", regions: ["South America"] },
  { code: "GUY", name: "Guyana", regions: ["South America"] },
  { code: "PAR", name: "Paraguay", regions: ["South America"] },
  { code: "PER", name: "Peru", regions: ["South America"] },
  { code: "SGS", name: "South Georgia And South Sandwich Islands", regions: ["South America"] },
  { code: "SUR", name: "Suriname", regions: ["South America"] },
  { code: "URU", name: "Uruguay", regions: ["South America"] },
];

export function cleanupName(name: string): string {
  if (!name.includes(",")) return name;
  const parts = name.split(", ");
  return parts[1] + " " + parts[0];
}

function buildRegions() {
  const regions: { [regionName: string]: Country[] } = {};

  countries.forEach((country) => {
    country.regions.forEach((countryRegion) => {
      if (regions[countryRegion]) {
        regions[countryRegion].push(country);
      } else {
        regions[countryRegion] = [country];
      }
    });
  });
  return regions;
}

export function getCountriesForRegion(regionName: string): Country[] {
  return regions[regionName];
}

export function getRegionsForCountry(countryCodeList: string[]): string[] {
  /* eslint-disable */
  return uniq(flatten(countries
    .filter((country: Country) => countryCodeList.includes(country.code))
    .map((c: Country) => c.regions),
  ));
  /* eslint-enable */
}

export const regions = buildRegions();
export const regionNames = Object.keys(regions).sort();
