import { computed, defineComponent } from "vue";
export default defineComponent({
  name: "DmsSpan",
  props: {
    coordinate: {
      type: Number,
      required: true,
      default: 0
    },
    mode: {
      type: String,
      required: false,
      default: "longitude"
    }
  },
  setup(props) {
    const span = computed(() => props.mode === "longitude" ? 360 : 180);
    const dms = computed(() => {
      const rollovers = Math.floor(props.coordinate / span.value + 0.5);
      let normalizedCoord = props.coordinate - span.value * rollovers;
      let dir = props.mode === "longitude" ? "E" : "N";
      if (normalizedCoord < 0) {
        dir = props.mode === "longitude" ? "W" : "S";
        normalizedCoord = -normalizedCoord;
      }
      const deg = Math.floor(normalizedCoord);
      const min = Math.floor(60 * (normalizedCoord - deg));
      const sec = Number(60 * (60 * (normalizedCoord - deg) - min));
      return {
        dir,
        deg,
        min,
        sec
      };
    });
    return {
      dms
    };
  }
});