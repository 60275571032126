import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_account_check_outline_icon = _resolveComponent("account-check-outline-icon");
  const _component_account_outline_icon = _resolveComponent("account-outline-icon");
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createBlock(_component_el_button, {
    class: "header-button snipcart-customer-signin",
    circle: "",
    plain: ""
  }, {
    default: _withCtx(() => [_ctx.isSignedIn ? (_openBlock(), _createBlock(_component_account_check_outline_icon, {
      key: 0
    })) : (_openBlock(), _createBlock(_component_account_outline_icon, {
      key: 1
    }))]),
    _: 1
  });
}