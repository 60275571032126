import {
  VuexModule,
  getModule,
  Module,
  Action,
  Mutation,
} from "vuex-module-decorators";
import axios, { AxiosInstance } from "axios";
import {
  ProductClient,
  PublicationClient,
  PublisherClient,
  RequestInfoClient,
  SearchClient,
} from "@/lib/evg-shop";

@Module({ namespaced: true, name: "api", dynamic: true, store })
export default class ApiModule extends VuexModule {
  baseUrl?: string;
  // note: we cannot store non-serializable objects in the store
  // axiosInstance?: AxiosInstance;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(options: any) {
    super(options);
    this.baseUrl = (store.state as IAppState).baseUrl;
  }

  get instance(): AxiosInstance {
    return axios.create({
      baseURL: this.baseUrl,
    });
  }

  get csrfToken(): string | undefined {
    const kvp = decodeURIComponent(document.cookie)
      .split(";")
      .find((c: string) => c.match(/^\s*CSRF-TOKEN=/));
    if (kvp) {
      return kvp.split("=")[1];
    }
  }

  get hasCsrfToken(): boolean {
    return this.csrfToken !== undefined;
  }

  get csrfInstance(): AxiosInstance {
    const token = this.csrfToken;
    if (token) {
      return axios.create({
        baseURL: this.baseUrl,
        headers: { "X-CSRF-TOKEN": token },
      });
    }
    return this.instance;
  }

  get searchClient(): SearchClient {
    return new SearchClient(this.baseUrl, this.instance);
  }

  get publicationClient(): PublicationClient {
    return new PublicationClient(this.baseUrl, this.instance);
  }

  get productClient(): ProductClient {
    return new ProductClient(this.baseUrl, this.instance);
  }

  get publisherClient(): PublisherClient {
    return new PublisherClient(this.baseUrl, this.instance);
  }

  get rfpClient(): RequestInfoClient {
    return new RequestInfoClient(this.baseUrl, this.csrfInstance);
  }

  @Mutation
  clearCsrfToken(): void {
    const kvp = decodeURIComponent(document.cookie)
      .split(";")
      .filter((c: string) => !c.match(/^\s*CSRF-TOKEN=/));
    document.cookie = encodeURIComponent(kvp.join(";"));
  }

  @Action
  fetchCsrfToken(): Promise<void> {
    if (!this.baseUrl) {
      Bugsnag.leaveBreadcrumb("fetchCsrfToken: no baseUrl");
      return Promise.reject();
    }
    return axios
      .get(this.baseUrl + "/RequestInfo", { withCredentials: true })
      .then(() => {
        if (!this.hasCsrfToken) {
          Bugsnag.leaveBreadcrumb("fetchCsrfToken: no token fetched");
        }
      });
  }
}

import store, { IAppState } from ".";
import Bugsnag from "@bugsnag/js";
export const apiModule = getModule(ApiModule, store);
