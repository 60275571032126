import capitalize from "lodash/capitalize";

export interface IBasemap {
  name: string;
  provider: string;
  title: string;
  kind: "xyz" | "bing";
  url?: string;
  key?: string;
  imagerySet?: string;
  attribution?: string | string[];
}

const mt = [
  "basic",
  "bright",
  "outdoor",
  "pastel",
  "streets",
  "toner",
  "topo",
  "topographique",
  "voyager",
  "positron",
];
const mtjpg = ["osm-standard", "hybrid", "winter"];

export const getAvailableBasemaps = (mapTilerKey: string): IBasemap[] => {
  return [
    ...mt.map(
      (name: string) =>
        ({
          provider: "MapTiler",
          name: capitalize(name),
          title: `mt-${name}`,
          kind: "xyz",
          url: `https://api.maptiler.com/maps/${name}/256/{z}/{x}/{y}.png?key=${mapTilerKey}`,
          attribution: "MapTiler",
        } as IBasemap)
    ),
    ...mtjpg.map(
      (name: string) =>
        ({
          provider: "MapTiler",
          name: capitalize(name),
          title: `mt-${name}`,
          kind: "xyz",
          url: `https://api.maptiler.com/maps/${name}/256/{z}/{x}/{y}.jpg?key=${mapTilerKey}`,
          attribution: "MapTiler",
        } as IBasemap)
    ),
  ];
};
