import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  class: "label-text"
};
const _hoisted_3 = ["itemprop"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _ctx.content ? (_openBlock(), _createElementBlock("tr", _hoisted_1, [_createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.label), 1), _createElementVNode("td", {
    itemprop: _ctx.itemprop
  }, [_renderSlot(_ctx.$slots, "default", {}, () => [_createTextVNode(_toDisplayString(_ctx.content), 1)])], 8, _hoisted_3)])) : _createCommentVNode("", true);
}