import { defaultRowsPerPage } from "@/lib/evg-shop-ext";
import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";

class DataTableModule extends VuexModule {
  lastFilters: IFilters = new Filters();
  searchParams: ISearchParams = {
    offset: undefined,
    limit: defaultRowsPerPage,
    sort: "not_provided",
    order: "asc",
  };

  get filters(): IFilters | undefined {
    return this.lastFilters;
  }
  get params(): ISearchParams {
    return this.searchParams;
  }

  @Mutation
  updateFilters(filters: IFilters): void {
    this.lastFilters = filters;
  }

  @Mutation
  setSearchParams(params: ISearchParams): void {
    this.searchParams = params;
  }
}

@Module({ namespaced: true, name: "pub", dynamic: true, store })
class PubModule extends DataTableModule {}

@Module({ namespaced: true, name: "prod", dynamic: true, store })
class ProdModule extends DataTableModule {}

import store from ".";
import { IFilters, ISearchParams, Filters } from "./search";
export const pubModule = getModule(PubModule, store);
export const prodModule = getModule(ProdModule, store);
