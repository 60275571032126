import { computed, defineComponent, ref, watch } from "vue";
import { snipcartModule } from "@/store/snipcart";
import debounce from "lodash/debounce";
import DeleteIcon from "vue-material-design-icons/Delete.vue";
export default defineComponent({
  components: {
    DeleteIcon
  },
  props: {
    cartItem: {
      type: Object,
      required: true
    },
    showPurchaseOptions: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  setup(props) {
    const item = ref(props.cartItem);
    const isDeleting = ref(false);
    const format = computed(() => item.value.description?.toLowerCase().includes("image") ? "Image" : item.value.description?.toLowerCase().includes("data") ? "Data" : "Paper");
    // split from string and return the before split string
    const description = computed(() => item.value.description?.replace(/-\s(paper|image|data)$/i, "") ?? "");
    const priceFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    });
    const formattedPrice = computed(() => priceFormatter.format(item.value.quantity * (item.value.unitPrice ?? 0)));
    const handleDelete = async () => {
      isDeleting.value = true;
      await snipcartModule.removeItem(props.cartItem);
      isDeleting.value = false;
    };
    const optionsField = computed(() => item.value.customFields?.find(field => field?.name === "Paper Type" || field?.name === "Image Format"));
    const handleChange = debounce(async () => await snipcartModule.updateItem(item.value), 500, {
      trailing: true
    });
    watch(() => props.cartItem, () => item.value = props.cartItem);
    return {
      item,
      format,
      description,
      formattedPrice,
      optionsField,
      isDeleting,
      handleChange,
      handleDelete
    };
  }
});