import "core-js/modules/es.array.push.js";
import { defineComponent, ref, watch } from "vue";
import VariantCheckbox from "./VariantCheckbox.vue";
import isEqual from "lodash/isEqual";
import DatabaseMarkerOutlineIcon from "vue-material-design-icons/DatabaseMarkerOutline.vue";
import FileImageIcon from "vue-material-design-icons/FileImage.vue";
import MapOutlineIcon from "vue-material-design-icons/MapOutline.vue";
export default defineComponent({
  name: "AddToCart",
  components: {
    VariantCheckbox,
    DatabaseMarkerOutlineIcon,
    FileImageIcon,
    MapOutlineIcon
  },
  emits: ["mouse:over", "mouse:leave", "update:selection"],
  props: {
    active: {
      type: Object,
      required: true
    },
    primary: {
      type: Object,
      required: true
    },
    emphasize: {
      type: Object,
      required: false
    },
    selection: {
      type: Object,
      required: false
    },
    size: {
      type: String,
      default: "medium"
    }
  },
  setup(props, {
    emit
  }) {
    const variantsToLabels = v => {
      const a = [];
      if (v?.print) a.push("Paper");
      if (v?.image) a.push("Image");
      if (v?.data) a.push("Data");
      return a;
    };
    const labelsToVariants = s => ({
      print: s.includes("Paper"),
      image: s.includes("Image"),
      data: s.includes("Data")
    });
    const selectedVariants = ref(variantsToLabels(props.selection));
    const onMouseOver = t => emit("mouse:over", t);
    const onMouseLeave = t => emit("mouse:leave", t);
    watch(() => selectedVariants.value, (current, previous) => {
      if (!isEqual(current, previous)) {
        emit("update:selection", labelsToVariants(current));
      }
    }, {
      deep: true
    });
    watch(() => props.selection, (current, previous) => {
      if (!isEqual(current, previous)) {
        selectedVariants.value = variantsToLabels(props.selection);
      }
    }, {
      deep: true
    });
    return {
      onMouseOver,
      onMouseLeave,
      selectedVariants
    };
  }
});