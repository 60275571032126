import {
  Filters as ApiFilters,
  IIntervalOfUInt64,
  IntervalOfUInt64,
  MatchCounts,
  PublicationMatch,
  Publisher,
  SearchClient,
} from "@/lib/evg-shop";
import {
  defaultRowsPerPage,
  noneVariant,
  Variants,
  variantsToFlag,
} from "@/lib/evg-shop-ext";
import { Country, countries } from "@/lib/filters/Countries";
import { IProductType, productTypes } from "@/lib/filters/Types";
import clone from "lodash/clone";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";

export interface ISortParams {
  sort: string;
  order?: "asc" | "desc";
}

export interface ISearchParams extends ISortParams {
  offset?: number;
  limit: number;
}

export class SearchParams implements ISearchParams {
  offset?: number;
  limit: number;
  sort: string;
  order?: "asc" | "desc";

  constructor(data?: ISearchParams) {
    this.offset = data?.offset;
    this.limit = data?.limit || defaultRowsPerPage;
    this.sort = data?.sort || "not_provided";
    this.order = data?.order || "asc";
  }

  init(data?: ISearchParams): void {
    this.offset = data?.offset;
    this.limit = data?.limit || defaultRowsPerPage;
    this.sort = data?.sort || "not_provided";
    this.order = data?.order || "asc";
  }

  copy(data: ISearchParams): void {
    this.offset = data.offset;
    this.limit = data.limit;
    this.sort = data.sort;
    this.order = data.order;
  }
}
/* Note: the interface and class below are different from those in evg-shop
    No variables can be undefined in this version.
    They do share the same names though,
    so take care of importing the correct one for your purpose
 */

export interface IFilters {
  types: number[];
  publishers: string[];
  inverseScale: IIntervalOfUInt64;
  year: IIntervalOfUInt64;
  countryCodes: string[];
  languages: string[];
  region: string;
  areaOfInterest?: number[][];
  variants: Variants;
}

const createInterval = (data?: IIntervalOfUInt64) => {
  const interval = new IntervalOfUInt64();
  interval.init(data || {});
  return interval;
};

export class Filters implements IFilters {
  types: number[];
  publishers: string[];
  inverseScale: IIntervalOfUInt64;
  year: IIntervalOfUInt64;
  countryCodes: string[];
  languages: string[];
  region: string;
  areaOfInterest?: number[][];
  variants: Variants;

  constructor(data?: IFilters) {
    this.types = data?.types?.map((t: number) => t) || [];
    this.publishers = data?.publishers?.map((p: string) => p) || [];
    this.inverseScale = createInterval(data?.inverseScale);
    this.year = createInterval(data?.year);
    this.countryCodes = data?.countryCodes?.map((c: string) => c) || [];
    this.languages = data?.languages?.map((c: string) => c) || [];
    this.region = data?.region?.toString() || "";
    this.areaOfInterest = data?.areaOfInterest?.map((c: number[]) => c);
    this.variants = clone(data?.variants || noneVariant) as Variants;
  }

  init(data?: IFilters): void {
    this.types = data?.types?.map((t: number) => t) || [];
    this.publishers = data?.publishers?.map((p: string) => p) || [];
    this.inverseScale = createInterval(data?.inverseScale);
    this.year = createInterval(data?.year);
    this.countryCodes = data?.countryCodes?.map((c: string) => c) || [];
    this.languages = data?.languages?.map((c: string) => c) || [];
    this.region = data?.region?.toString() || "";
    this.areaOfInterest = data?.areaOfInterest?.map((c: number[]) => c);
    this.variants = clone(data?.variants || noneVariant) as Variants;
  }

  enforcePublisher(permId: string) {
    // We don't have a list of publishers to validate against so the most
    // we can do is make sure the permId is set on the search request
    // only way of getting to /search-by-publisher page is via link so generally this is safe
    this.publishers = [permId || ""];
  }

  enforceType(typePath: string): void {
    const pType = productTypes.find((p: IProductType) => p.path === typePath);
    const typeid = pType?.typeid || 0;
    if (!(this.types.length === 1 && this.types[0] === typeid)) {
      this.types = [typeid];
    }
  }

  enforceCountry(countryCode: string): void {
    if (typeof countryCode === "string") {
      countryCode = countryCode.toUpperCase();
    }
    const isValidCountryCode = countries
      .map((c) => c.code)
      .includes(countryCode);
    const pCountryCode = isValidCountryCode ? countryCode : countries[0].code;
    if (
      !(this.countryCodes.length === 1 && this.countryCodes[0] === pCountryCode)
    ) {
      this.countryCodes = [pCountryCode];
    }
  }
}

const createApiFilters = (filters: IFilters): ApiFilters => {
  const apiFilters = new ApiFilters({
    ...filters,
    availability:
      filters.variants &&
      (filters.variants.print ||
        filters.variants.image ||
        filters.variants.data)
        ? variantsToFlag(filters.variants)
        : undefined,
    language: filters.languages.join("|"),
    publisher: filters.publishers.join("|"),
  });
  if (!store.getters.hasMapLayout) {
    apiFilters.areaOfInterest = undefined;
  }
  return apiFilters;
};

@Module({ namespaced: true, name: "search", dynamic: true, store })
export default class SearchModule extends VuexModule {
  sharedFilters: IFilters = new Filters();
  searchParams: ISearchParams = {
    offset: undefined,
    limit: defaultRowsPerPage,
    sort: "not_provided",
    order: "asc",
  };
  languageList: string[] = [];
  countryList: Country[] = [];
  publisherList: Publisher[] = [];

  get filters(): IFilters {
    return this.sharedFilters;
  }

  get apiFilters(): ApiFilters {
    return createApiFilters(this.sharedFilters);
  }

  get params(): ISearchParams {
    return this.searchParams;
  }

  get client(): SearchClient {
    return apiModule.searchClient;
  }

  get isInitialSearch(): boolean {
    return !this.searchParams.offset;
  }

  get languages(): string[] {
    return this.languageList;
  }

  get countries(): Country[] {
    return this.countryList;
  }

  get publishers(): Publisher[] {
    return this.publisherList;
  }

  @Mutation
  updateFilters(filters: IFilters): void {
    this.sharedFilters = new Filters(filters);
  }

  @Mutation
  updateAOI(aoi: number[][]): void {
    this.sharedFilters.areaOfInterest = aoi;
  }

  @Mutation
  resetFilters(): void {
    this.sharedFilters = new Filters();
  }

  @Mutation
  setSearchParams(params: ISearchParams): void {
    this.searchParams = params;
  }

  @Mutation
  enforcePublisher(permId: string): void {
    if (!this.sharedFilters.publishers?.length) {
      // We don't have a list of publishers to validate against so the most
      // we can do is make sure the permId is set on the search request
      // only way of getting to /search-by-publisher page is via link so generally this is safe
      this.sharedFilters.publishers = [permId || ""];
    }
  }

  @Mutation
  enforceType(typePath: string): void {
    const pType = productTypes.find((p: IProductType) => p.path === typePath);
    const typeid = pType?.typeid || 0;
    if (
      !(
        this.sharedFilters.types.length === 1 &&
        this.sharedFilters.types[0] === typeid
      )
    ) {
      this.sharedFilters.types = [typeid];
    }
  }

  @Mutation
  enforceCountry(countryCode: string): void {
    if (typeof countryCode === "string") {
      countryCode = countryCode.toUpperCase();
    }
    const isValidCountryCode = countries
      .map((c) => c.code)
      .includes(countryCode);
    const pCountryCode = isValidCountryCode ? countryCode : countries[0].code;
    if (
      !(
        this.sharedFilters.countryCodes.length === 1 &&
        this.sharedFilters.countryCodes[0] === pCountryCode
      )
    ) {
      this.sharedFilters.countryCodes = [pCountryCode];
    }
  }

  @Mutation
  setLanguages(languages: string[]): void {
    this.languageList = languages;
  }

  @Mutation
  setCountryCodes(codes: string[]): void {
    this.countryList = countries.filter((c: Country) => codes.includes(c.code));
  }

  @Mutation
  setPublishers(pubs: Publisher[]): void {
    this.publisherList = pubs;
  }

  @Action
  initializeAvailableFilters(): Promise<void[]> {
    const client = apiModule.searchClient;
    const lp = client
      .getLanguages()
      .then((languages: string[]) => this.setLanguages(languages));
    const cp = client
      .getCountries()
      .then((codes: string[]) => this.setCountryCodes(codes));
    const pp = client
      .getPublishers()
      .then((pubs: Publisher[]) => this.setPublishers(pubs));
    return Promise.all([lp, cp, pp]);
  }

  @Action
  fetchMatchCounts(filters: IFilters): Promise<MatchCounts> {
    return this.client.countAggregated(createApiFilters(filters));
  }

  @Action
  async searchWithFilters(v: {
    filters: IFilters;
    params: ISearchParams;
  }): Promise<PublicationMatch[]> {
    const p = new SearchParams(v.params);
    const apiFilters = createApiFilters(v.filters);
    return await apiModule.searchClient.findAggregated(
      p.sort,
      p.order,
      p.limit,
      p.offset,
      apiFilters
    );
  }
}

import store from ".";
import { apiModule } from "./api";
export const searchModule = getModule(SearchModule, store);
