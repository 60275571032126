import FilterBox from "@/components/FilterBox.vue";
import { MatchCounts, Publisher } from "@/lib/evg-shop";
import { getPublicationSlug, getPublisherSlug } from "@/lib/evg-shop-ext";
import { computed, defineComponent, onMounted, onUpdated, reactive, ref, watch, watchEffect } from "vue";
import { Filters, searchModule } from "@/store/search";
import { browseModule } from "@/store/browse";
import PublicationTable from "@/components/PublicationTable.vue";
import { apiModule } from "@/store/api";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "PublicationByPublisher",
  props: {
    permId: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: false
    }
  },
  components: {
    FilterBox,
    PublicationTable
  },
  methods: {
    getPublicationSlug: p => getPublicationSlug(p)
  },
  setup(props) {
    const columns = ["aggregate.name", "aggregate.publisherName", "aggregate.scaleName", "matches"].map(p => ({
      prop: p
    }));
    const publisher = reactive(new Publisher());
    const matchCounts = reactive(new MatchCounts());
    const filters = computed(() => searchModule.filters);
    const editFilters = ref(new Filters(filters.value));
    const blankFilters = ref(new Filters());
    const router = useRouter();
    const hasCounts = computed(() => {
      return matchCounts.publications > 0 || matchCounts.products > 0;
    });
    const canonicalUrl = computed(() => {
      let canonical = undefined;
      if (publisher.permId) {
        canonical = `/search-by-publisher/${publisher.permId}/${getPublisherSlug(publisher)}`;
      }
      return canonical;
    });
    const publisherClient = apiModule.publisherClient;
    const updateCounts = () => {
      searchModule.enforcePublisher(props.permId);
      searchModule.fetchMatchCounts(filters.value).then(c => {
        matchCounts.init(c);
      });
    };
    const updateFilters = newFilters => {
      searchModule.updateFilters(newFilters);
      updateCounts();
    };
    const resetFilter = () => {
      searchModule.resetFilters();
      searchModule.enforcePublisher(props.permId);
      editFilters.value = new Filters(filters.value);
      updateCounts();
    };
    const onEnter = () => {
      if (browseModule.activeType !== props.permId) {
        browseModule.updateActiveType(props.permId);
      }
      document.title = `Global Explorer - search: ${props.slug}`;
      blankFilters.value.enforcePublisher(props.permId);
      updateCounts();
    };
    const getDetails = publisherPermId => {
      if (!publisherPermId) {
        publisher.init(new Publisher());
        return;
      }
      publisherClient.getByPermId(publisherPermId).then(pub => {
        publisher.init(pub);
        enforceCanonicalUrl;
      });
    };
    watch(() => props.permId, onEnter);
    watch(() => searchModule.filters, () => editFilters.value.init(searchModule.filters), {
      deep: true
    });
    const enforceCanonicalUrl = () => {
      if (canonicalUrl.value && router.currentRoute.value.fullPath !== canonicalUrl.value) {
        router.replace({
          path: canonicalUrl.value
        });
      }
    };
    watchEffect(() => getDetails(props.permId));
    onMounted(onEnter);
    onUpdated(onEnter);
    return {
      blankFilters,
      canonicalUrl,
      columns,
      filters,
      editFilters,
      hasCounts,
      matchCounts,
      publisher,
      updateFilters,
      resetFilter
    };
  }
});