import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import store from "@/store";
import Home from "@/views/Home.vue";
import SearchByPublisher from "@/views/SearchByPublisher.vue";
import LicenseStatement from "@/views/LicenseStatement.vue";
import SearchByType from "@/views/SearchByType.vue";
import SearchByCountry from "@/views/SearchByCountry.vue";
import Product from "@/views/Product.vue";
import EvHeader from "@/components/AppHeader.vue";
import EvFooter from "@/components/AppFooter.vue";
import Catalog from "@/views/Catalog.vue";

export default [
  {
    path: "/browse",
    name: "Catalog",
    components: {
      default: Catalog,
      header: EvHeader,
      footer: EvFooter,
    },
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ): void => {
      store.commit("setLayout", "catalog");
      next();
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/search-by-publisher/:permId/:slug?",
        name: "byPublisher",
        component: SearchByPublisher,
        props: (route) => ({
          permId: route.params.permId,
          slug: route.params.slug,
        }),
      },
      {
        path: "/license-statement",
        name: "licenseStatement",
        component: LicenseStatement,
      },
      {
        path: "/search-by-type/:slug",
        name: "byType",
        component: SearchByType,
        props: (route) => ({ primaryType: route.params.slug }),
      },
      {
        path: "/search-by-country/:countryCode/:slug?",
        name: "byCountry",
        component: SearchByCountry,
        props: (route) => ({ countryCode: route.params.countryCode }),
      },
      {
        path: "/product/:permId/:slug?",
        name: "product",
        component: Product,
        props: (route: RouteLocationNormalized): Record<string, string> => {
          if (Array.isArray(route.params.permId)) return {};
          return { productId: route.params.permId as string };
        },
      },
      {
        path: "/publication/:permId/:slug?",
        name: "publication",
        component: Product,
        props: (route: RouteLocationNormalized): Record<string, string> => {
          if (Array.isArray(route.params.permId)) return {};
          return { publicationId: route.params.permId as string };
        },
      },
    ],
  },
] as RouteRecordRaw[];
